<template>
    <b-modal
        size="lg"
        hide-footer
        v-model="isOpen"
        title="Order Sku Edit"
        id="editOrderModal"
        ref="editOrderModal"
        modal-class="edit-order-modal"
    >
        <template v-slot:modal-header="{ close }">
            <span class="fs18 lh28 fw500">
                 Package Dimension
            </span>

            <b-button variant="rounded" @click="close()">
                <i class="icon-close-variant"></i>
            </b-button>
        </template>

        <b-form @submit.prevent="formSubmit">
            <div class="mw596 mx-auto pt-46">
                <div class="row">
                    <div class="col-md-4 col-6">
                        <b-form-group
                          id="length-field"
                          label="Length (cm)"
                          label-for="length"
                        >
                            <b-input 
                                id="length" 
                                v-model="form.itemLength"
                                type="number" 
                                placeholder="0"
                                required
                                min="1"
                                max="999"
                            ></b-input>
                        </b-form-group>
                    </div>

                    <div class="col-md-4 col-6">
                        <b-form-group id="width-field" label="Width (cm)" label-for="width">
                            <b-input 
                                id="width" 
                                v-model="form.itemWidth"
                                type="number" 
                                placeholder="0"
                                required
                                min="1"
                                max="999"
                            ></b-input>
                        </b-form-group>
                    </div>

                    <div class="col-md-4 col-6">
                        <b-form-group
                            id="height-field"
                            label="Height (cm)"
                            label-for="height"
                        >
                            <b-input 
                                id="height" 
                                v-model="form.itemHeight"
                                type="number" 
                                required
                                min="1"
                                max="999"
                                placeholder="0"
                            ></b-input>
                        </b-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-md-6">
                        <b-form-group id="mode-type-field" label="Mode" label-for="mode-type">
                            <multiselect
                                id="mode-type"
                                v-model="form.mode"
                                :options="$store.getters.mode.domestic"
                                label="name"
                                :searchable="false"
                                :show-labels="false"
                                placeholder="Select mode type"
                            ></multiselect>
                         </b-form-group>
                    </div>

                    <div class="col-md-6 col-6">
                        <div
                            class="form-group"
                            id="weight-field"
                            label="Weight (kg)"
                            label-for="weight"
                        >
                            <label for="weight">
                                Weight (kg)
                            </label>
                            <b-input 
                                id="weight" 
                                v-model="form.itemWeight"
                                type="number" 
                                placeholder="0.00"
                                required
                                step="0.01"
                                :min="$store.state.minW"
                                :max="$store.state.maxW"
                            ></b-input>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-md-6 d-flex align-items-center mt-10 mb-10" v-if="saveasSKUFlag == true">
                        <b-form-group class="d-inline mb-0">
                            <b-form-checkbox
                                id="agreement-check"
                                v-model="form.saveasSKU"
                                name="agreement-check"
                            >
                                Save data in SKU
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                </div>

                <div class="row pt-24">
                    <div class="col-md-auto col-6 pr-sm-4 pr-xs-4">
                        <b-button
                            variant="primary btn-lg w-sm-100"
                            class="m-0 mr-8"
                            type="submit"
                        >
                            Update
                        </b-button>
                    </div>

                    <div class="col-md-auto col-6 pl-sm-4 pl-xs-4">
                        <b-button
                          id="btnHide"
                          variant="secondary-modal btn-lg w-sm-100"
                          @click="hideModal"
                          class="m-0"
                        >
                          Cancel
                        </b-button>
                    </div>
                </div>

            </div>
        </b-form>
    </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "EditOrderModal",
    props: ['modalOpen', 'orderIDs', 'saveasSKUFlag',"from"],

    data() {
        return {
            value: null,
            typeValue: null,
            isOpen: this.modalOpen,
            modeTypes: this.$store.getters.modes,
            form: {},
            enableProp: false,
        };
    },

    components: {
        Multiselect,
    },

    methods: {
        async formSubmit() {

            const newData = this.form;

            this.form.modeType = newData.mode ? newData.mode.value : '';
            this.form.orderID = this.orderIDs

            let updatedData = Object.assign({}, newData);

            delete updatedData.mode

            let response = await this.axios.post('/editdim', updatedData);

            if (response.data.success == true) {
                this.$emit('updatePackages', this.form.orderID)
                if(this.from == true) {
                    setTimeout(() => { 
                        this.$emit('reFresh', {refresh:true})
                        this.hideModal();
                    }, 1000)

                } else {
                    this.hideModal();
                }
            }
            else {
                this.popToast("failed", "failed", response.data.error);
            }

        },
        hideModal() {
            this.$bvModal.hide("editOrderModal");
        },
    },
    watch: {
        isOpen(newValue) {
            if(!newValue) {
                this.form = {}
            }
        }
    }

};
</script>
