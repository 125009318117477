var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "editOrderModal",
      attrs: {
        size: "lg",
        "hide-footer": "",
        title: "Order Sku Edit",
        id: "editOrderModal",
        "modal-class": "edit-order-modal"
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("span", { staticClass: "fs18 lh28 fw500" }, [
                _vm._v(" Package Dimension ")
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.formSubmit($event)
            }
          }
        },
        [
          _c("div", { staticClass: "mw596 mx-auto pt-46" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-4 col-6" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "length-field",
                        label: "Length (cm)",
                        "label-for": "length"
                      }
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "length",
                          type: "number",
                          placeholder: "0",
                          required: "",
                          min: "1",
                          max: "999"
                        },
                        model: {
                          value: _vm.form.itemLength,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "itemLength", $$v)
                          },
                          expression: "form.itemLength"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-4 col-6" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "width-field",
                        label: "Width (cm)",
                        "label-for": "width"
                      }
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "width",
                          type: "number",
                          placeholder: "0",
                          required: "",
                          min: "1",
                          max: "999"
                        },
                        model: {
                          value: _vm.form.itemWidth,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "itemWidth", $$v)
                          },
                          expression: "form.itemWidth"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-4 col-6" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "height-field",
                        label: "Height (cm)",
                        "label-for": "height"
                      }
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          id: "height",
                          type: "number",
                          required: "",
                          min: "1",
                          max: "999",
                          placeholder: "0"
                        },
                        model: {
                          value: _vm.form.itemHeight,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "itemHeight", $$v)
                          },
                          expression: "form.itemHeight"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col col-md-6" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "mode-type-field",
                        label: "Mode",
                        "label-for": "mode-type"
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          id: "mode-type",
                          options: _vm.$store.getters.mode.domestic,
                          label: "name",
                          searchable: false,
                          "show-labels": false,
                          placeholder: "Select mode type"
                        },
                        model: {
                          value: _vm.form.mode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "mode", $$v)
                          },
                          expression: "form.mode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "col-md-6 col-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    attrs: {
                      id: "weight-field",
                      label: "Weight (kg)",
                      "label-for": "weight"
                    }
                  },
                  [
                    _c("label", { attrs: { for: "weight" } }, [
                      _vm._v(" Weight (kg) ")
                    ]),
                    _c("b-input", {
                      attrs: {
                        id: "weight",
                        type: "number",
                        placeholder: "0.00",
                        required: "",
                        step: "0.01",
                        min: _vm.$store.state.minW,
                        max: _vm.$store.state.maxW
                      },
                      model: {
                        value: _vm.form.itemWeight,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "itemWeight", $$v)
                        },
                        expression: "form.itemWeight"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _vm.saveasSKUFlag == true
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col col-md-6 d-flex align-items-center mt-10 mb-10"
                    },
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "d-inline mb-0" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "agreement-check",
                                name: "agreement-check"
                              },
                              model: {
                                value: _vm.form.saveasSKU,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "saveasSKU", $$v)
                                },
                                expression: "form.saveasSKU"
                              }
                            },
                            [_vm._v(" Save data in SKU ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "row pt-24" }, [
              _c(
                "div",
                { staticClass: "col-md-auto col-6 pr-sm-4 pr-xs-4" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "m-0 mr-8",
                      attrs: {
                        variant: "primary btn-lg w-sm-100",
                        type: "submit"
                      }
                    },
                    [_vm._v(" Update ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-auto col-6 pl-sm-4 pl-xs-4" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "m-0",
                      attrs: {
                        id: "btnHide",
                        variant: "secondary-modal btn-lg w-sm-100"
                      },
                      on: { click: _vm.hideModal }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }