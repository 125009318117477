<template>
	<div class="shipments-detail">
		<AppTopbar
		title="Order Detail"
		buttonText="New Order"
		buttonIconClass="icon-add"
		buttonLink="/new"
		/>

		<OrderDetails :order="order" @refreshOrder="refreshOrder" :shipment="shipment" :tracking="tracking" :shipEvents="shipEvents" :events="events" :channels="channels" :statuses="statuses" v-if="!isBusy" />

		<div v-else class="container-fluid pt-100 pt-md-80 pb-80 pb-md-0">
			<div class="text-center text-gray-600 mt-20 my-2">
				<b-spinner class="align-middle"></b-spinner>
				<strong>Loading...</strong>
			</div>
		</div>
	</div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import OrderDetails from "@/components/OrderDetails.vue";

export default {
	name: "OrderDetail",
	title: "Shyplite - Detail View",
	components: {
		AppTopbar,
		OrderDetails,
	},
	data() {
		return {
			isBusy 		: false,
			order 		: {},
			shipment	: {},
			tracking	: {},
			channels	: {},
			shipEvents	: {},
			events 		: {},
			statuses 	: {},
		}
	},
	created() {
		this.getDetails()
		this.getChannels()
		this.getEvents()
		this.getStatus()
	},
	methods: {
		refreshOrder(flag) {
			if (flag == true) {
				this.getDetails()
			} else {
				
			}
		},
		async getDetails() {
			try {
				this.isBusy = true
				const id = this.$route.params.id
				const res = await this.axios.get(`/order/${id}`)
        
				if (res.data.success) {
					this.order 		= res.data.order
					this.isBusy 	= false
				}
				else {
					this.$router.push(res.data.redirect)
				}

				let pkgDim = undefined;
				if (this.order.packetdiamention){
					try {
						pkgDim = JSON.parse(this.order.packetdiamention);
					} catch(e) {
						pkgDim = this.order.packetdiamention;
					}

					if (Object.keys(pkgDim).length) {

						let a = {
							"sku":"", 
							"category": "", 
							"name": "Net Dimension", 
							"length": pkgDim.itemLength, 
							"width": pkgDim.itemWidth, 
							"height": pkgDim.itemHeight, 
							"weight": pkgDim.itemWeight, 
							"modeType": "", 
							"quantity": "",
							"price": "null"
						}

						this.order.packageDetails.push(a);

					}
				} 

			} catch(e) {
				console.log(e);
			}
		},
		async getChannels() {
			const res = await this.axios.get('/statics/channels')
			this.channels = res.data
		},
		async getEvents() {
			const res = await this.axios.get('/statics/events')
			this.events = res.data
		},
		async getStatus() {
			const res = await this.axios.get('/statics/statuses')
			this.statuses = res.data.forwardStatus
		},
		goBack() {
			this.$router.go(-1);
		},
	},
	watch: {
		'$route.params.id': function(newValue) {
			this.getDetails()
		}
	}
};
</script>
