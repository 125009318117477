<template>
	<div>
		<b-modal
		ref="fullOrderEditModal"
		id="modal-tall"
		size="lg"
		title="Rebook Shipment"
		v-model="isOpen"
		modal-class="book-modal"
        :hide-footer="true"
        :dialog-class="{ largeModal: large }"
		>
		<template v-slot:modal-header="{ close }">
			<span class="d-block fs18 lh28 fw500 mt-8">
				Edit Order - {{ form.orderId }}
			</span>
			<!-- Emulate built in modal header close button action -->
			<b-button size="sm" variant="rounded" @click="close()">
				<i class="icon-close-variant fs14"></i>
			</b-button>
		</template>
		<b-form @submit.prevent="saveButton('bookSave')">
			<div class="">
				<div class="row">
					<div class="col">
						<span class="fs18 lh28 fw500 d-block pb-20 pb-md-20">
							Order Information
						</span>
					</div>
				</div>


				<span class="fs16 lh28 fw500" v-if="errorArr.length">
					<img src="@/assets/images/warning1.png" class="warning1" alt="">
					Errors List</span>
				<div class="col mb-20 text-left error" v-if="errorArr.length">
					<ol v-if="errorArr.length > 1">
						<li v-for="item in errorArr">
							{{item}}
						</li>
					</ol> 
					<span v-else class="fs14 ml-10" v-for="item in errorArr">{{item}}</span>
				</div>

				<div class="row">
					<div class="col">
						<b-form-group
						id="warehouse-field"
						label="Warehouse"
						label-for="warehouse"
						>
						<multiselect
						id="warehouse"
						v-model="form.sellerAddressId"
						:options="editOrderWarehouses"
						label="name"
                        ref="warehouse"
						@input="Onchange(form.sellerAddressId)"
						:show-labels="false"
						track-by="name"
						></multiselect>
                        <span v-if="!form.sellerAddressId" class="fs12 text-red">Warehouse selection is required</span>
						<span v-else class="fs12 text-gray-600">{{ warehouseAddress }}</span>
					</b-form-group>
				</div>
			</div>

			<div class="row">
<!--           <div class="col-md-6">
            <b-form-group id="mode-field" label="Mode" label-for="mode">
              <multiselect
                id="mode"
                v-model="form.modeType"
                :options="modeType"
                label="name"
                :searchable="false"
                :show-labels="false"
              ></multiselect>
            </b-form-group>
        </div> -->
        <div class="col-md-6">
        	<b-form-group
        		id="orderid-field"
        		label="Order ID"
        		label-for="order-id"
        	>
        	<b-form-input
        		class="order_id"
        		:class="{sameOrderid : giveNeworder}"
        		id="order-id"
        		minlength="2"
          		maxlength="35"
          		required
          		pattern="[0-9a-z#A-Z_ /-]*"
          		title="Maximum 35 characters allowed"
        		placeholder="Enter order id"
        		v-model="form.orderId"
        		ref="orderId"
        	></b-form-input>
        	<small class="text-red">Note: Order ID editing is only for Create New</small>
        </b-form-group>
    </div>

    <div class="col-6 col-md-6">
    	<b-form-group id="date-field" label="Date" label-for="date">
    		<date-picker
    			prefix-class="xmx"
    			v-model="form.orderDate"
    			ref="orderDate"
    			id="date"
    			placeholder="yyyy-mm-dd"
    			required
    		>
    		<i slot="icon-calendar" class="icon-calendar"></i>
    	</date-picker>
    </b-form-group>
</div>
</div>

<div class="row">

	<div class="col-md-6">
		<b-form-group id="type-field" label="Order Type" label-for="type">
			<multiselect
				id="type"
				v-model="form.orderType"
            	ref="orderType"
				:options="types"
				:searchable="false"
				:show-labels="false"
			></multiselect>
            <span v-if="!form.orderType" class="fs12 text-red">Order Type is required</span>
		</b-form-group>
	</div>

	<div class="col-6 col-md-6">
		<b-form-group
		id="total-value-field"
		label="Total Value"
		label-for="total-value"
		>
		<b-input-group append="₹">
			<b-input
			id="total-value"
			placeholder="0.00"
			v-model="form.totalValue"
			ref="totalValue"
			required
			></b-input>
		</b-input-group>
	</b-form-group>
</div>
</div>

<div class="row" v-if="user.choice == 0">
	<div class="col col-md-6">
		<b-form-group id="mode-field" label="Mode" label-for="weight">
			<multiselect
			id="mode"
			v-model="form.modeType"
            ref="modeType"
			:options="modes"
			placeholder="Select mode type"
			:searchable="false"
            :show-labels="false"
			track-by="name"
			label="name"
			>
		</multiselect>
	</b-form-group>
</div>
</div>

<div v-if="form.totalValue >= 50000" class="row">
	<div class="col-6">
      <b-form-group
        id="length-field"
        label="E-WayBill"
        label-for="ewaybill"
        class="wsnw"
      >
        <b-input
          id="ewaybill"
          v-model="form.ewaybill"
          type="text"
          placeholder="E-WayBill"
          required
        ></b-input>
      </b-form-group>
    </div>
</div>


<div class="row">
	<div class="col">
		<span class="fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24">
			Customer Information
		</span>
	</div>
</div>

<div class="row">
	<div class="col">
		<b-form-group
		id="customer-name-field"
		label="Name"
		label-for="customer-name"
		>
		<b-form-input
		id="customer-name"
		placeholder="Enter customer name"
		required
		v-model="form.customerName"
		ref="customerName"
        maxlength="35"
		></b-form-input>
	</b-form-group>
</div>
</div>

<div class="row">
	<div class="col-md-6">
		<b-form-group
		id="phone-field"
		label="Phone Number"
		label-for="phone-number"
		>
		<b-input-group>
			<b-form-input
			id="phone-number"
			placeholder="123 456 7890"
			type="tel"
			v-model="form.customerContact"
			ref="customerContact"
			required
			maxlength="10"
            minlength="10"
            :formatter="numbers"
            oninvalid="this.setCustomValidity('Enter valid mobile number.')"
            oninput="this.setCustomValidity('')"
			></b-form-input>
			<div class="input-group-append">
				<div class="input-group-text">
					<i class="icon-phone fs16 text-gray-600"></i>
				</div>
			</div>
		</b-input-group>
	</b-form-group>
</div>
<div class="col-md-6">
	<b-form-group id="city-field" label="City" label-for="city">
		<b-form-input 
		id="city" 
		placeholder="Enter city"
		v-model="form.customerCity"
		required
		ref="customerCity"
        maxlength="35"
		></b-form-input>
	</b-form-group>
</div>
</div>

<div class="row" v-if="this.maxLength">
	<div class="col-12 col-md-9">
		<b-form-group
		id="address-field"
		label="Full Address"
		label-for="address"
        :description="
         'Characters: ' + form.customerAddress.length + ' / ' + this.maxLength">
		<b-form-input
		id="address"
		required
		placeholder="Enter customer address"
		v-model="form.customerAddress"
		ref="customerAddress"
		:maxlength="this.maxLength"
		></b-form-input>
	</b-form-group>
</div>
<div class="col-6 col-md-3">
	<b-form-group id="pin-field" label="Pin Code" label-for="pin">
		<b-form-input
			id="pin"
			type="text"
          	maxlength="6"
          	minlength="6"
          	required
          	oninvalid="this.setCustomValidity('Enter valid pincode.')"
          	oninput="this.setCustomValidity('')"
          	:formatter="numbers"
          	placeholder="110001"
		  	v-model="form.customerPinCode"
		  	ref="customerPinCode"
		></b-form-input>
	</b-form-group>
</div>
</div>
</div>

<div class="pb-15 pb-md-15">
	<div class="col">
		<span class="fs18 lh28 fw500 d-block pt-16 pb-15 pb-md-15">
			Package Details
		</span>
	</div>
	<div class="col" v-if="user.invoicing && showgstinvoice && form.orderType != 'REVERSE'">
		<span class="d-block lh28 fw500">
	      Do you want GST Compliant invoice along with label?
	      <b-form-checkbox unchecked-value="0" value="1" v-model="form.isTaxInvoice" class="d-inline mr-20 ml-20 h2" switch></b-form-checkbox>
	    </span>
	</div>
	
</div>
<div class="row">
	<div class="col">
		<b-table
		class="dark-header new-order"
		:fields="computedFields"
		:items="form.items"
		responsive
		>

		<!-- A custom formatted header cell for field 'dimensions' -->
		<template v-slot:head(dimensions)>
			<span class="underline-dashed">
				Dimensions
			</span>
			<i
			class="icon-tutorial ml-4 vam text-gray-400"
			v-b-tooltip.hover.right="'SKU Dimensions!'"
			></i>
		</template>

		<!-- A custom formatted header cell for field 'sku' -->
		<template v-slot:head(sku)>
			SKU
		</template>

		<!-- A custom formatted header cell for field 'quantity' -->
		<template v-slot:head(quantity)>
			QTY. *
		</template>

		<template v-slot:head(value)>
			Value (₹) <span v-if="form.isTaxInvoice == 1 && user.invoicing && form.orderType != 'REVERSE'">*</span>
		</template>

		<!-- A custom formatted cell for field 'dimensions' -->
		<template v-slot:cell(sku)="data">
			{{data.item.sku}}
		</template>

		<template v-slot:cell(selected)="{ item, field: { key }}">
			<div class="d-flex justify-content-center w-sm-50 align-items-center">
				<b-form-checkbox v-model="item.selected" @input="deSelect($event)"></b-form-checkbox>
			</div>
		</template>

		<template v-slot:cell(name)="data">
			<b-form-input 
				v-model="data.item.name" 
				placeholder="Product Name" 
				class="input-sm sku-name w40"
				maxlength="100">		
			</b-form-input>		
		</template>

		<template v-slot:cell(dimensions)="data">
			<div class="d-flex align-items-center">
				<b-form-input
					v-model="data.item.dimensions.length"
					placeholder="0"
					type="number"
        			min="0"
        			class="small w40"
				></b-form-input>
				<span class="px-4">x</span>
				<b-form-input
					v-model="data.item.dimensions.width"
					placeholder="0"
					type="number"
        			min="0"
        			class="small w40"
				></b-form-input>
				<span class="px-4">x</span>
				<b-form-input
					v-model="data.item.dimensions.height"
					placeholder="0"
					type="number"
        			min="0"
        			class="small w40"
				></b-form-input>
			</div>
		</template>

		<!-- A custom formatted cell for field 'weight' -->
		<template v-slot:cell(weight)="data">
			<div class="d-flex align-items-center">
				<b-form-input
					v-model="data.item.weight"
					placeholder="0.00"
					step=".01"
					type="number"
        			min="0"
        			class="small w72"
				></b-form-input>
			</div>
		</template>

		<!-- A custom formatted cell for field 'quantity' -->
		<template v-slot:cell(quantity)="data">
			<b-form-input
				v-model="data.item.quantity"
				placeholder="0"
				type="number"
      			min="0"
      			class="small w40"
			></b-form-input>
		</template>

		<!-- A custom formatted cell for field 'value' -->
		<template v-slot:cell(value)="data">
			<div class="d-flex align-items-center">
				<b-form-input
				v-model="data.item.value"
				step=".01"
				placeholder="0.00"
				type="number"
        		min="0"
        		class="small w72"
				></b-form-input>
			</div>
		</template>

		<template v-slot:cell(hsn)="data">
			<div class="d-flex align-items-center">
				<b-form-input
				v-model="data.item.hsn"
				placeholder="123456"
        		class="small w72"
				></b-form-input>
			</div>
		</template>

		<template v-slot:cell(discount)="data">
			<div class="d-flex align-items-center">
				<b-form-input
				v-model="data.item.discount"
				step=".01"
				placeholder="0.00"
				type="number"
        		min="0"
        		class="small w72"
				></b-form-input>
			</div>
		</template>

		<template v-slot:cell(taxRate)="data">
			<div class="d-flex align-items-center">
				<b-form-input
				v-model="data.item.taxRate"
				step=".01"
				placeholder="0.00"
				type="number"
        		min="0"
        		class="small w72"
				></b-form-input>
			</div>
		</template>
	</b-table>
</div>
</div>


<div class="row" v-if="(fields[0].key == 'sku' || fields[1].key == 'sku')">
	<div class="col">
		<span class="fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24">
			Net Dimensions
		</span>
	</div>
</div>
<div class="row" v-if="(fields[0].key == 'sku' || fields[1].key == 'sku')">
	<div class="col">
		<b-table
		v-model="form.packetdiamention"
		class="dark-header new-order"
		:fields="netfields"
		:items="form.packetdiamention"
		responsive
		>

		<!-- A custom formatted cell for field 'dimensions' -->
		<template v-slot:head(dimensions)>
			<span class="underline-dashed">
				Dimensions
			</span>
			<i
			class="icon-tutorial ml-4 vam text-gray-400"
			v-b-tooltip.hover.right="'Net Dimensions LxWxH in cm'"
			></i>
		</template>

		<template v-slot:cell(dimensions)="data">
			<div class="d-flex align-items-center">
				<span class="mr-5">L</span>
				<b-form-input
					required
					v-model="data.item.itemLength"
					placeholder="0"
					type="number"
        			min="1"
        			class="small w50px"
				></b-form-input>
				<span class="px-4">x</span>
				<span class="mr-5 ml-5">W</span>
				<b-form-input
					required
					v-model="data.item.itemWidth"
					placeholder="0"
					type="number"
        			min="1"
        			class="small w50px"
				></b-form-input>
				<span class="px-4">x</span>
				<span class="mr-5 ml-5">H</span>
				<b-form-input
					required
					v-model="data.item.itemHeight"
					placeholder="0"
        			type="number"
        			min="1"
				class="small w50px"
				></b-form-input>
			</div>
		</template>

		<!-- A custom formatted cell for field 'weight' -->
		<template v-slot:cell(weight)="data">
			<div class="d-flex align-items-center">
				<b-form-input
					required
					step=".01"
        			type="number"
        			min="0.01"
					placeholder="0.00"
					v-model="data.item.itemWeight"
					class="small w72 net-weight"
				></b-form-input>
				<span class="ml-4">
					kg
				</span>
			</div>
		</template>
      </b-table>
  </div>
</div>


<div class="row mt-20 mb-20" v-if="form.isTaxInvoice == '1' && user.invoicing && form.orderType != 'REVERSE'">
    <div class="col-md-7 col-12">
        <span class="d-block fs18 lh28 fw500 mb-14">
            Additional Information
        </span>

        <div class="row">

            <div class="col-md-3 col-6">
                <b-form-group
                    id="invoiceNumber-field"
                    label="Invoice Number"
                    label-for="invoiceNumber"
                    class="wsnw"
                >
                    <b-input
                        id="invoiceNumber"
                        v-model="form.invoiceNumber"
                        placeholder="Invoice number"
                        type="text"
                        title="Invoice number"
                        required
                        maxlength="15"
                        minlength="3"
                        ref= "invoiceNumber"
                    ></b-input>
                </b-form-group>
            </div>

            <div class="col-md-3 col-6">
                <b-form-group id="invoiceDate-field" label="Invoice Date" label-for="invoiceDate">
                  <date-picker
                      v-model="form.invoiceDate"
                      id="invoiceDate"
                      placeholder="yyyy-mm-dd"
                      type="date"
                      ref="invoiceDate"
                      prefix-class="xmx"
                      title="Invoice date"
                      required
                  >
                      <i slot="icon-calendar" class="icon-calendar"></i>
                  </date-picker>
              </b-form-group>
            </div>

            <div class="col-md-3 col-6">
                <b-form-group
                    id="otherCharges-field"
                    label="Shipping / Other Charges"
                    label-for="otherCharges"
                    class="wsnw"
                >
                  <b-input-group append="₹">
                    <b-input
                        id="otherCharges"
                        v-model="form.otherCharges"
                        placeholder="0.00"
                        title="Shipping / Other charges"
                        type="number"
                        min="0"
                        ref="otherCharges"
                        step="0.01"
                    ></b-input>
                  </b-input-group>
                </b-form-group>
            </div>

        </div>
    </div>
</div>

<b-button variant="primary" :disabled="disableSave" @click="saveButton('onlySave')" class="m-0 mr-8 mb-20">SAVE</b-button>

<b-button id="btnHide" variant="primary" type="submit" :disabled="disableSave" class="m-0 mb-20">SAVE & BOOK</b-button>

<b-button id="btnHide" variant="primary" :disabled="disableCreate" v-if="splitOrder" @click="saveButton('createNew')" class="ml-10 mb-20">CREATE NEW</b-button>

<EditCalcPricing @openModal="openPricingModal" :formInfo.sync="calcForm" :choiceData.sync="calcData" ref="editcalcpricing" />
</b-form>
</b-modal>
</div>
</template>

<script>
import { VMoney } from "v-money";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import EditCalcPricing from "@/components/EditCalcPricing.vue"
import { mapState } from "vuex";

export default {
	name: "ShipmentRebook",
	props: ['modalOpen', 'editFormData'],
	data() {
		return {
			isOpen: this.modalOpen,
			date: null,
			value: null,
			allSelected: false,
			importedID: null,
			form: {},
			dialogData: {},
			warehouseName: null,
			option: [],
			splitOrder: false,
			checkbox:true,
			disableNet:false,
      // modeType: [],
      calcData:[],
      calcForm:[],
      editOrderWarehouses: [],
      orderType: "",
      disableCreate:false,
      showgstinvoice:true,
      totalValue: "",
      maxLength: null,
      customerName: "",
      customerPinCode: "",
      customerContact: "",
      customerAddress: "",
      disableSave:false,
      choiceMode: '',
      giveNeworder:false,
      types: ["PREPAID", "COD", "REVERSE"],
      errorArr:[],
      items: [],
      warehouseAddress:'',
      fields: [
      {
      	key:"selected",
      	label:""
      },
      {
      	key: "sku",
      	sortable: true,
      	class: "pl-0",
      },
      {
      	key: "name",
      	label:"Name *",
      	sortable: true,
      },
      { key: "dimensions", label: "Dimensions" },
      {
      	key: "weight",
      	label: "Weight (kg)",
      	sortable: true,
      },
      {
      	key: "quantity",
      	label:"Quantity *",
      	sortable: true,
      },
      {
      	key: "value",
      	sortable: true,
      	class: "pr-0",
      },
      {
      	key: "hsn",
      	label: "HSN",
      	class: "pr-0",
      },
      {
      	key: "discount",
      	label: "Discount",
      	class: "pr-0",
      },
      {
      	key: "taxRate",
      	label: "Tax Rate",
      	class: "pr-0",
      },
      ],
      netfields: [
      { key: "dimensions", label: "Dimensions" },
      {
      	key: "weight",
      	sortable: true,
      }
      ],
      money: {
      	decimal: ",",
      	thousands: ".",
      	precision: 2,
      	masked: false,
      },
  };
},
components: {
	DatePicker,
	Multiselect,
	EditCalcPricing
},
directives: { money: VMoney },
watch: {
	selectedRows: function() {
		if (this.selectedRows.length == this.items.length) {
			this.allSelected = true;
		}
	},
	editFormData: function(newValue) {
		if(newValue) {
			this.editAndRebook(newValue)
			this.maxLength = 140
		}
	},
	isOpen: function(newValue) {
		if(newValue) {
			this.disableSave = false
      		this.giveNeworder = false
		}
	}
},
mounted() {
      // this.staticData();
      this.editWarehouses();
  },
  computed:{
  	modes() {

  		let modes = undefined;
  		// MPS
  		if (this.form.channel == 9) {
  			modes = this.$store.getters.mode.mps;
  		}
  		// International
  		else if (this.form.channel == 12) {
  			modes = this.$store.getters.mode.intl;
  		}
  		// B2B
  		else if (this.form.channel == 37) {
  			modes = this.$store.getters.mode.b2b;
  		}
  		else {
  			modes = this.$store.getters.mode.domestic;
  		}

  		return modes;
  	},
  	user() {
		return this.$store.getters.user
	},
  	large() {
  		if((this.form.isTaxInvoice && this.form.isTaxInvoice == 1) && (this.user.invoicing && this.form.orderType != 'REVERSE')){
  			return true
  		} else {
  			return false
  		}
  	},
  	computedFields() {
  		// this.form && this.form.items && this.form.items[0].hasOwnProperty('hsn') && this.form.items[0].hsn == undefined
  		if (this.form.items && this.form.items.length == 1) {
  			// this.fields.splice(0,1)
  			let temp = Object.assign([],this.fields)
  			temp.splice(0,1)
  			if ((this.form.isTaxInvoice == 0 || this.form.isTaxInvoice == undefined) || !(this.user.invoicing && this.form.orderType != 'REVERSE')) {
  				temp.splice(6,3)
  			} 
  			return temp
  		} else {
  			if((this.form.isTaxInvoice == 0 || this.form.isTaxInvoice == undefined) || !(this.user.invoicing && this.form.orderType != 'REVERSE')) {
  				let temp = Object.assign([],this.fields)
  				temp.splice(7,3)
	  			return temp
  			} else {
				return this.fields
			}	
  		}

  	},
  	...mapState(["user"])
  },
  methods: {
  	Onchange(value) {
        if(value) {
            const res = this.form.addresses.find(item => item.id == value.id )
            this.warehouseAddress = res.address
        }
  	},
  	openPricingModal: function() {
  		this.$refs.editcalcpricing.isOpen = !this.$refs
  		.editcalcpricing.isOpen;
  	},
  	deSelect(value) {
  		let abc = []
  		abc = this.form.items.filter(i => i.selected == true)
  		if (abc.length < this.form.items.length) {
  			if (abc.length == 0) {
	  			this.disableCreate = true;
	  		}
	  		else {
	  			this.disableCreate = false;
	  		}
  			this.disableSave = true
  		} 
  		else {
  			this.disableCreate = false;
  			this.disableSave = false
  		}
  	},
  	async editWarehouses() {
        this.editOrderWarehouses = this.$store.getters.warehouses.map((i) => {
            return {
                name: i.name, 
                id: i.id,
                address: i.address,
                $isDisabled: i.active == 1 && i.approved == 1 ? false: true,
            };
        })

  	},
  	toggleAllRows() {
  		if (this.allSelected == false) {
  			this.items.forEach((item) => {
  				this.$set(item, "selected", true);
  				this.allSelected = true;
  			});
  		} else {
  			this.selectedRows.forEach((item) => {
  				this.$delete(item, "selected");
  				this.allSelected = false;
  			});
  		}
  	},
  	isvalidateZeroOrBlank(input = "") {
  		if (String(input).trim() == 0 || input == 0) {
  			return true;
  		}
  		return false;
  	},
  	async editAndRebook(value) {

  		if(value.address) {
	  		const res = value.address.find(item => item.id == value.order.warehouseID )
	  		this.warehouseAddress = res.address
  		}

  		if (value.order.hasOwnProperty('errors')) {
  			this.errorArr = [...value.order.errors]
  		}

  		let editableData = {

  			orderId: value.order.importedID,
  			sellerAddressId: {
  				id: value.order.warehouseID,
  				name: value.order.pickupDetails.name,
  			},
  			tableID:value.order.id,
  			orderType: value.order.type,
  			orderDate: value.order.date,
  			totalValue: value.order.totalValue,
  			customerName: value.order.customerData.name,
  			customerPinCode: value.order.customerData.pin,
  			customerCity: value.order.customerData.city,
  			customerContact: value.order.customerData.contact,
  			isTaxInvoice : value.order.isTaxInvoice,
  			invoiceNumber : value.order.invoiceNumber,
  			isTaxInvoice : value?.order?.isTaxInvoice,
  			otherCharges : value.order.otherCharges,
  			invoiceDate : value.order.invoiceDate,
  			customerAddress: value.order.customer.address,
  			packetdiamention: (Object.entries(value.order.packetdiamention).length == 0 && Object(value.order.packageDetails).length > 1) ? [ { 'itemWidth': null, 'itemHeight': null, 'itemLength': null, 'itemWeight': null }] : [value.order.packetdiamention],
  			addresses: value.address,
  			mode:value.order.mode,
  			channel:value.order.channel,

  			items: value.order.packageDetails.map(item => {
  				return {
  					fieldRequired: Object.keys(value.order.packetdiamention).length == 0 ? true : false,
  					selected : true,
  					sku: item.sku,
  					name: item.name,
  					dimensions: { width: item.width, height: item.height, length: item.length },
  					weight: item.weight,
  					quantity: item.quantity,
  					value: item.price,
  					hsn:item.hsn || '',
  					discount:item.discount || '',
  					taxRate:item.taxRate || ''
  				}
  			})
  		}

  		if(this.user.choice !=1 ) {
  			let mode = this.modes.find(item => item.value == editableData.mode);
  			if (mode && mode.value && mode.name) {
  				editableData.modeType = {
  					value: mode.value,
  					name: mode.name
  				};
  			} else {
  				editableData.modeType = {
  					value: "0",
  					name: "Select Option"
  				};
  			}
  		}

  		editableData.orderType = editableData.orderType.toUpperCase()
  		editableData.orderDate = new Date(editableData.orderDate)
  		editableData.invoiceDate = new Date(editableData.invoiceDate)

  		if(editableData.items.length) {
  			if(editableData.items.length > 1) {

  				editableData['oldlength'] = []
  				editableData['oldwidth'] = []
  				editableData['oldheight'] = []
  				editableData['oldweight'] = []
  				editableData['oldquantity'] = []
  				editableData['oldprice'] = []
  				editableData['olditemName'] = []

  				this.splitOrder = true

  				editableData.items.forEach(value => editableData['oldlength'].push(value.dimensions.length))
  				editableData.items.forEach(value => editableData['oldwidth'].push(value.dimensions.width))
  				editableData.items.forEach(value => editableData['oldheight'].push(value.dimensions.height))
  				editableData.items.forEach(value => editableData['oldquantity'].push(value.quantity))
  				editableData.items.forEach(value => editableData['oldprice'].push(value.value))
  				editableData.items.forEach(value => editableData['oldweight'].push(value.weight))
  				editableData.items.forEach(value => editableData['olditemName'].push(value.name))

  			} else {
  				editableData['oldlength'] = editableData.items[0].dimensions.length
  				editableData['oldwidth'] = editableData.items[0].dimensions.width
  				editableData['oldheight'] = editableData.items[0].dimensions.height
  				editableData['oldweight'] = editableData.items[0].weight
  				editableData['oldquantity'] = editableData.items[0].quantity
  				editableData['oldprice'] = editableData.items[0].value
  				editableData['olditemName'] = editableData.items[0].name
  				this.splitOrder = false
  			}
  		}

  		editableData['totalWeight'] = 0;
  		editableData['packageLength'] = 0;
  		editableData['packageWidth'] = 0;
  		editableData['packageHeight'] = 0;

  		this.choiceMode = editableData.mode

  		this.form = editableData;
  		if(Object.keys(this.form.packetdiamention[0]) == 0) {
  			this.disableNet = true
  		} else {
  			this.disableNet = false
  		}
  	},

  	hideModal() {
  		this.$bvModal.hide("modal-tall");
  	},
    // Submit button
    async saveButton(type) {
        if(!this.form.sellerAddressId) {
            this.$refs.warehouse.$el.focus();
            return false
        }
        if(!this.form.orderId) {
            this.$refs.orderId.$el.focus();
            return false
        }
        if(!this.form.orderDate) {
            this.$refs.orderDate.$el.focus();
            this.popToast('failed','Failed','Order Date is Mandatory')
            return false
        }
        if(!this.form.orderType) {
            this.$refs.orderType.$el.focus();
            return false
        }
        if(this.user.choice == 0 && this.form.modeType.value == 0) {
            this.$refs.modeType.$el.focus();
            return false
        }
        if(!this.form.totalValue) {
            this.$refs.totalValue.$el.focus();
            return false
        }
        if(!this.form.customerName) {
            this.$refs.customerName.$el.focus();
            return false
        }
        if(!this.form.customerContact || (this.form.customerContact && this.form.customerContact.length != 10)) {
            this.$refs.customerContact.$el.focus();
            return false
        }
        if(!this.form.customerCity) {
            this.$refs.customerCity.$el.focus();
            return false
        }
        if(!this.form.customerAddress) {
            this.$refs.customerAddress.$el.focus();
            return false
        }
        if(!this.form.customerPinCode) {
            this.$refs.customerPinCode.$el.focus();
            return false
        }
        if(this.form.isTaxInvoice == 1) {
        	if(!this.form.invoiceNumber) {
        		this.$refs.invoiceNumber.$el.focus();
            	return false
        	}
        	if(this.form.invoiceDate == "Invalid Date" || this.form.invoiceDate == null) {
        		this.$refs.invoiceDate.$el.focus();
        		this.popToast('failed','Failed','Invoice Date is Mandatory')
            	return false
        	}
        }

        
    	this.form.items.forEach(value => this.form['totalWeight'] += parseInt(value.weight))

    	let wrongDimension = false;

    	let netDimension = {};

    	Object.keys(this.form.packetdiamention[0]).forEach((element, index) => {

    		if (this.form.packetdiamention[0][element] <= 0 || String(this.form.packetdiamention[0][element]).trim() == '') {
    			wrongDimension = true;
    		}

    		netDimension[element] = this.form.packetdiamention[0][element];

    	});

    	if (wrongDimension) {
    		this.popToast("failed", "Failed", "Please Fill Net Dimension Details");
    		return;
    	}

    	if (Object.keys(netDimension).length) {
    		this.form['netDimension'] = netDimension
    	}


    	if(this.form.items.length) {
    		if(this.form.items.length > 1) {

    			this.form['length'] = []
    			this.form['width'] = []
    			this.form['height'] = []
    			this.form['weight'] = []
    			this.form['quantity'] = []
    			this.form['price'] = []
    			this.form['itemName'] = []
    			this.form['packageWeight'] = 0

    			if(this.form.isTaxInvoice == "1" && (this.user.invoicing && this.form.orderType != 'REVERSE')) {
    				this.form['hsn'] = []
	    			this.form['discount'] = []
	    			this.form['taxRate'] = []
    				this.form.items.forEach(value => this.form['hsn'].push(value.hsn))
	    			this.form.items.forEach(value => this.form['discount'].push(Number(value.discount)))
	    			this.form.items.forEach(value => this.form['taxRate'].push(Number(value.taxRate)))
    			}

    			this.form.items.forEach(value => this.form['packageWeight'] += parseInt(value.weight))
    			this.form.items.forEach(value => this.form['length'].push(value.dimensions.length))
    			this.form.items.forEach(value => this.form['width'].push(value.dimensions.width))
    			this.form.items.forEach(value => this.form['height'].push(value.dimensions.height))
    			this.form.items.forEach(value => this.form['quantity'].push(value.quantity))
    			this.form.items.forEach(value => this.form['price'].push(value.value))
    			this.form.items.forEach(value => this.form['weight'].push(value.weight))
    			this.form.items.forEach(value => this.form['itemName'].push(value.name))
    			
    		}
    		else if(this.form.items[0].name){
    			this.form['packageLength'] = this.form.items[0].dimensions.length
    			this.form['packageWidth'] = this.form.items[0].dimensions.width
    			this.form['packageHeight'] = this.form.items[0].dimensions.height
    			this.form['packageWeight'] = this.form.items[0].weight
    			this.form['length'] = this.form.items[0].dimensions.length
    			this.form['width'] = this.form.items[0].dimensions.width
    			this.form['height'] = this.form.items[0].dimensions.height
    			this.form['weight'] = this.form.items[0].weight
    			this.form['quantity'] = this.form.items[0].quantity
    			this.form['price'] = this.form.items[0].value
    			this.form['itemName'] = this.form.items[0].name
    			if(this.form.isTaxInvoice == 1 && (this.user.invoicing && this.form.orderType != 'REVERSE')) {
    				this.form['hsn'] = this.form.items[0].hsn
	    			this.form['discount'] = Number(this.form.items[0].discount)
	    			this.form['taxRate'] = Number(this.form.items[0].taxRate)
    			}
    		} else {}
    	}
    
    	if (this.form.sellerAddressId.id) {

	      	let formItemData = Object.assign({}, this.form);

    		// For multiple items
    		if (!Array.isArray(formItemData['itemName'])) {

    			Object.keys(formItemData).forEach( function(element, index) {
    				formItemData[element] = [formItemData[element]];
    			});
    		}

    		let wrongPackage = false;

    		// return

    		if (Object.keys(formItemData.items).length > 1) {
    			formItemData['itemName'].forEach( (element, index) => {

	    			let isSelected = true;

	    			if (type == 'createNew') {
		    			isSelected = formItemData['items'][index].selected;
	    			}

	    			if (isSelected) {

		    			let itemName = element
		    			let length = formItemData['length'][index];;
		    			let width = formItemData['width'][index];
		    			let height = formItemData['height'][index];
		    			let weight = formItemData['weight'][index];
		    			let quantity = formItemData['quantity'][index];
		    			let price = formItemData['price'][index];
		    			if (this.isvalidateZeroOrBlank(quantity) || this.isvalidateZeroOrBlank(itemName) 
		    				|| (this.form.isTaxInvoice == 1 && this.user.invoicing && this.form.orderType != 'REVERSE' && this.isvalidateZeroOrBlank(price)))   {
		    				wrongPackage = true;
		    			}
	    			}
	    		});
    		} else {
    			if(this.isvalidateZeroOrBlank(formItemData.items[0][0].name) || 
    				this.isvalidateZeroOrBlank(formItemData.items[0][0].quantity) || 
    				(this.form.isTaxInvoice == 1 && this.user.invoicing && this.form.orderType != 'REVERSE' && this.isvalidateZeroOrBlank(formItemData.items[0][0].value))) {
    						wrongPackage = true;
    			}
    		}

    		if (wrongPackage) {
	    		this.popToast("failed", "Failed", "All Package Details Fields * are Mandatory");
	    		return;
    		}

        }
        // aage chalaana h toh niche return hataao
        // return;

        if (this.user.choice == 1) {
        	this.form['modeType'] = 'save'
        }  

        if (this.user.choice == 1) {
        	this.form.modeType = this.choiceMode
        }

      if (type == 'onlySave') {                         // Only Save the Current Order
      	let data = Object.assign({}, this.form)

      	if (this.user.choice == 1) {
      		data.modeType = "save"
      	} 

      	if (this.user.choice != 1) {
      		data.modeType = data.modeType.value
      	}  
        // changing address details
        data.addresses.filter(i => {
        	if(i.approved && i.id == data.sellerAddressId.id) {
        		data['originPin'] = i.pincode,
        		data['originCity'] = i.city
        	}
        })

        // changing Date details
        const date = new Date(data.orderDate)
        const invdate  = new Date(data.invoiceDate)
        var options = {
        	year: "numeric",
        	month: "2-digit",
        	day: "2-digit"
        }
        data.orderDate =  date.toLocaleDateString(['en-CA'],options)
        data.invoiceDate =  invdate.toLocaleDateString(['en-CA'],options)

        // changing Address ID details
        data.sellerAddressId = data.sellerAddressId.id
        delete data.addresses
        if(this.user.choice == 0) {
        	data['saveAuto'] = 'true'
        }

        try {
        	const res = await this.axios.post(`/order/edit/${data.tableID}`, data)
        	if (res.data.success) {
        		this.popToast("booked", "Success!", res.data.message);
        		setTimeout(() => { 
	        		this.$router.push(`${data.tableID}`)
	        		this.$emit('reFresh', {refresh:true})
	        		this.hideModal();
        		}, 1000)
        	} else {
        		this.popToast("failed", "Failed!", res.data.message);
        	}
        } catch(e) {
        	this.popToast("failed", "Failed!", "Form Submission failed!! Please Try again later");
        } 
    } 

      else if(type == 'createNew') {                 //Create New order after selecting or deselecting
      	let newData = Object.assign({}, this.form)

      	if (this.user.choice != 1) {
      		newData.modeType = newData.modeType.value
      	} 
        // changing address details
        newData.addresses.filter(i => {
        	if(i.approved && i.id == newData.sellerAddressId.id) {
        		newData['originPin'] = i.pincode,
        		newData['originCity'] = i.city
        	}
        })

        // changing Date details
        const date = new Date(newData.orderDate)
        const invdate = new Date(newData.invoiceDate)
        var options = {
        	year: "numeric",
        	month: "2-digit",
        	day: "2-digit"
        }
        newData.orderDate =  date.toLocaleDateString(['en-CA'],options)
        newData.invoiceDate = invdate.toLocaleDateString(['en-CA'],options)

        // changing Address ID details
        newData.sellerAddressId = newData.sellerAddressId.id

        newData['channel'] = 'SINGLE'

        // sending only Checked items to Create New
        newData.items = newData.items.filter(i => i.selected == true)
        delete newData.addresses

        try {
        	const res = await this.axios.post('/createorder', newData)
        	if (res.data.success) {
        		this.popToast("booked", "Success!", `New Order with name ${newData.orderId} created Successfully`);
        		setTimeout(() => { 
        			this.$router.push('/shipments'); 
        		}, 2000)
        	} else {
        		this.popToast("failed", "Failed!", res.data.message);
        		if (res.data.message.includes('already')) {
        			this.giveNeworder = true
        			let element = document.getElementById('modal-tall___BV_modal_content_');    
        			element.scrollIntoView(true);
        		} else {
        			this.giveNeworder = false
        		}
        	}
        } catch(e) {
        	this.popToast("failed", "Failed!", "New Order Creation failed!! Please Try Again Later");
        }
    }

	//Book and Save the Order
    else if (type == 'bookSave') {  

       	let calData = Object.assign({}, this.form)

       	calData.orderType = calData.orderType.toLowerCase()
        // changing address details
        calData.addresses.filter(i => {
        	if(i.approved && i.id == calData.sellerAddressId.id) {
        		calData['originPin'] = i.pincode,
        		calData['originCity'] = i.city
        	}
        })
        delete calData.addresses

        calData.packetdiamention = Object.assign({},calData.netDimension)

        if(this.user.choice == 1 ) {

        	calData.packageWeight = calData.packetdiamention.itemWeight;
            calData.packageLength = calData.packetdiamention.itemLength;
            calData.packageWidth = calData.packetdiamention.itemWidth;
            calData.packageHeight = calData.packetdiamention.itemHeight;

        	try {
        		const res = await this.axios.post('/choice/getchoicedata',calData)
        		if (res.data.success) {
        			this.calcForm = calData
        			this.calcData = res.data.choicepricing
        			this.openPricingModal();
        		} else {
        			this.popToast("failed","Failed",`Service not available from ${calData.originPin} to ${calData.customerPinCode}. Please check the serviceability or enable other couriers.`);
        		}
        	} catch(e) {
        		this.popToast("failed","Failed","Data Fetching Failed!! Please Try Again");
        	}
        } else {
        	calData['bookAuto'] = 'true'
        	calData.sellerAddressId = calData.sellerAddressId.id
        	calData.modeType = calData.modeType.value
        	try {
        		const res = await this.axios.post(`/order/edit/${calData.tableID}`,calData)
        		if (res.data.success) {
        			this.popToast("booked", "Success!", `Order with name ${calData.orderId} booked Successfully`);
        			setTimeout(() => { 
        				this.$router.push('/shipments'); 
        			}, 2000)
        		} else {
        			this.popToast("failed", "Failed!", res.data.message);
        		}
        	} catch(e) {
        		this.popToast("failed", "Failed!", "Booking Failed!! Please Try Again Later");
        	}
        }
        
    } else {

    }       
},
},
};
</script>

<style type="text/css" scoped>
.sameOrderid {
	border: 2px solid red !important;
}
/deep/ .sku-name {
    width: 8rem !important;	
}

/deep/ .modal-content {
	width: max-content !important;
}
.warning1{
      height: 2rem;
      width: 2rem;
}

/deep/ .largeModal {
	max-width: 64rem !important;
}

.error{
  background: rgba(255, 130, 70, 0.2);
  height: max-content;
  padding: 0.5rem;
  color: black;
  border: 1px #ffa6a6;
  border-radius: 5px;
}
ol {
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	padding-inline-start: 25px;
};
</style>
