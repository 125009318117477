var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "fullOrderEditModal",
          attrs: {
            id: "modal-tall",
            size: "lg",
            title: "Rebook Shipment",
            "modal-class": "book-modal",
            "hide-footer": true,
            "dialog-class": { largeModal: _vm.large }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("span", { staticClass: "d-block fs18 lh28 fw500 mt-8" }, [
                    _vm._v(" Edit Order - " + _vm._s(_vm.form.orderId) + " ")
                  ]),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "rounded" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-close-variant fs14" })]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.isOpen,
            callback: function($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen"
          }
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveButton("bookSave")
                }
              }
            },
            [
              _c("div", {}, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "span",
                      { staticClass: "fs18 lh28 fw500 d-block pb-20 pb-md-20" },
                      [_vm._v(" Order Information ")]
                    )
                  ])
                ]),
                _vm.errorArr.length
                  ? _c("span", { staticClass: "fs16 lh28 fw500" }, [
                      _c("img", {
                        staticClass: "warning1",
                        attrs: {
                          src: require("@/assets/images/warning1.png"),
                          alt: ""
                        }
                      }),
                      _vm._v(" Errors List")
                    ])
                  : _vm._e(),
                _vm.errorArr.length
                  ? _c(
                      "div",
                      { staticClass: "col mb-20 text-left error" },
                      [
                        _vm.errorArr.length > 1
                          ? _c(
                              "ol",
                              _vm._l(_vm.errorArr, function(item) {
                                return _c("li", [
                                  _vm._v(" " + _vm._s(item) + " ")
                                ])
                              }),
                              0
                            )
                          : _vm._l(_vm.errorArr, function(item) {
                              return _c("span", { staticClass: "fs14 ml-10" }, [
                                _vm._v(_vm._s(item))
                              ])
                            })
                      ],
                      2
                    )
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "warehouse-field",
                            label: "Warehouse",
                            "label-for": "warehouse"
                          }
                        },
                        [
                          _c("multiselect", {
                            ref: "warehouse",
                            attrs: {
                              id: "warehouse",
                              options: _vm.editOrderWarehouses,
                              label: "name",
                              "show-labels": false,
                              "track-by": "name"
                            },
                            on: {
                              input: function($event) {
                                return _vm.Onchange(_vm.form.sellerAddressId)
                              }
                            },
                            model: {
                              value: _vm.form.sellerAddressId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sellerAddressId", $$v)
                              },
                              expression: "form.sellerAddressId"
                            }
                          }),
                          !_vm.form.sellerAddressId
                            ? _c("span", { staticClass: "fs12 text-red" }, [
                                _vm._v("Warehouse selection is required")
                              ])
                            : _c(
                                "span",
                                { staticClass: "fs12 text-gray-600" },
                                [_vm._v(_vm._s(_vm.warehouseAddress))]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "orderid-field",
                            label: "Order ID",
                            "label-for": "order-id"
                          }
                        },
                        [
                          _c("b-form-input", {
                            ref: "orderId",
                            staticClass: "order_id",
                            class: { sameOrderid: _vm.giveNeworder },
                            attrs: {
                              id: "order-id",
                              minlength: "2",
                              maxlength: "35",
                              required: "",
                              pattern: "[0-9a-z#A-Z_ /-]*",
                              title: "Maximum 35 characters allowed",
                              placeholder: "Enter order id"
                            },
                            model: {
                              value: _vm.form.orderId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "orderId", $$v)
                              },
                              expression: "form.orderId"
                            }
                          }),
                          _c("small", { staticClass: "text-red" }, [
                            _vm._v(
                              "Note: Order ID editing is only for Create New"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "date-field",
                            label: "Date",
                            "label-for": "date"
                          }
                        },
                        [
                          _c(
                            "date-picker",
                            {
                              ref: "orderDate",
                              attrs: {
                                "prefix-class": "xmx",
                                id: "date",
                                placeholder: "yyyy-mm-dd",
                                required: ""
                              },
                              model: {
                                value: _vm.form.orderDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "orderDate", $$v)
                                },
                                expression: "form.orderDate"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-calendar",
                                attrs: { slot: "icon-calendar" },
                                slot: "icon-calendar"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "type-field",
                            label: "Order Type",
                            "label-for": "type"
                          }
                        },
                        [
                          _c("multiselect", {
                            ref: "orderType",
                            attrs: {
                              id: "type",
                              options: _vm.types,
                              searchable: false,
                              "show-labels": false
                            },
                            model: {
                              value: _vm.form.orderType,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "orderType", $$v)
                              },
                              expression: "form.orderType"
                            }
                          }),
                          !_vm.form.orderType
                            ? _c("span", { staticClass: "fs12 text-red" }, [
                                _vm._v("Order Type is required")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "total-value-field",
                            label: "Total Value",
                            "label-for": "total-value"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { append: "₹" } },
                            [
                              _c("b-input", {
                                ref: "totalValue",
                                attrs: {
                                  id: "total-value",
                                  placeholder: "0.00",
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.totalValue,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "totalValue", $$v)
                                  },
                                  expression: "form.totalValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm.user.choice == 0
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "mode-field",
                                label: "Mode",
                                "label-for": "weight"
                              }
                            },
                            [
                              _c("multiselect", {
                                ref: "modeType",
                                attrs: {
                                  id: "mode",
                                  options: _vm.modes,
                                  placeholder: "Select mode type",
                                  searchable: false,
                                  "show-labels": false,
                                  "track-by": "name",
                                  label: "name"
                                },
                                model: {
                                  value: _vm.form.modeType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "modeType", $$v)
                                  },
                                  expression: "form.modeType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.form.totalValue >= 50000
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "length-field",
                                label: "E-WayBill",
                                "label-for": "ewaybill"
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  id: "ewaybill",
                                  type: "text",
                                  placeholder: "E-WayBill",
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.ewaybill,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "ewaybill", $$v)
                                  },
                                  expression: "form.ewaybill"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24"
                      },
                      [_vm._v(" Customer Information ")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "customer-name-field",
                            label: "Name",
                            "label-for": "customer-name"
                          }
                        },
                        [
                          _c("b-form-input", {
                            ref: "customerName",
                            attrs: {
                              id: "customer-name",
                              placeholder: "Enter customer name",
                              required: "",
                              maxlength: "35"
                            },
                            model: {
                              value: _vm.form.customerName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customerName", $$v)
                              },
                              expression: "form.customerName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "phone-field",
                            label: "Phone Number",
                            "label-for": "phone-number"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                ref: "customerContact",
                                attrs: {
                                  id: "phone-number",
                                  placeholder: "123 456 7890",
                                  type: "tel",
                                  required: "",
                                  maxlength: "10",
                                  minlength: "10",
                                  formatter: _vm.numbers,
                                  oninvalid:
                                    "this.setCustomValidity('Enter valid mobile number.')",
                                  oninput: "this.setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.customerContact,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customerContact", $$v)
                                  },
                                  expression: "form.customerContact"
                                }
                              }),
                              _c("div", { staticClass: "input-group-append" }, [
                                _c("div", { staticClass: "input-group-text" }, [
                                  _c("i", {
                                    staticClass: "icon-phone fs16 text-gray-600"
                                  })
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "city-field",
                            label: "City",
                            "label-for": "city"
                          }
                        },
                        [
                          _c("b-form-input", {
                            ref: "customerCity",
                            attrs: {
                              id: "city",
                              placeholder: "Enter city",
                              required: "",
                              maxlength: "35"
                            },
                            model: {
                              value: _vm.form.customerCity,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customerCity", $$v)
                              },
                              expression: "form.customerCity"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                this.maxLength
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-9" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "address-field",
                                label: "Full Address",
                                "label-for": "address",
                                description:
                                  "Characters: " +
                                  _vm.form.customerAddress.length +
                                  " / " +
                                  this.maxLength
                              }
                            },
                            [
                              _c("b-form-input", {
                                ref: "customerAddress",
                                attrs: {
                                  id: "address",
                                  required: "",
                                  placeholder: "Enter customer address",
                                  maxlength: this.maxLength
                                },
                                model: {
                                  value: _vm.form.customerAddress,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customerAddress", $$v)
                                  },
                                  expression: "form.customerAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 col-md-3" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "pin-field",
                                label: "Pin Code",
                                "label-for": "pin"
                              }
                            },
                            [
                              _c("b-form-input", {
                                ref: "customerPinCode",
                                attrs: {
                                  id: "pin",
                                  type: "text",
                                  maxlength: "6",
                                  minlength: "6",
                                  required: "",
                                  oninvalid:
                                    "this.setCustomValidity('Enter valid pincode.')",
                                  oninput: "this.setCustomValidity('')",
                                  formatter: _vm.numbers,
                                  placeholder: "110001"
                                },
                                model: {
                                  value: _vm.form.customerPinCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customerPinCode", $$v)
                                  },
                                  expression: "form.customerPinCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "pb-15 pb-md-15" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "fs18 lh28 fw500 d-block pt-16 pb-15 pb-md-15"
                    },
                    [_vm._v(" Package Details ")]
                  )
                ]),
                _vm.user.invoicing &&
                _vm.showgstinvoice &&
                _vm.form.orderType != "REVERSE"
                  ? _c("div", { staticClass: "col" }, [
                      _c(
                        "span",
                        { staticClass: "d-block lh28 fw500" },
                        [
                          _vm._v(
                            " Do you want GST Compliant invoice along with label? "
                          ),
                          _c("b-form-checkbox", {
                            staticClass: "d-inline mr-20 ml-20 h2",
                            attrs: {
                              "unchecked-value": "0",
                              value: "1",
                              switch: ""
                            },
                            model: {
                              value: _vm.form.isTaxInvoice,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "isTaxInvoice", $$v)
                              },
                              expression: "form.isTaxInvoice"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("b-table", {
                      staticClass: "dark-header new-order",
                      attrs: {
                        fields: _vm.computedFields,
                        items: _vm.form.items,
                        responsive: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "head(dimensions)",
                          fn: function() {
                            return [
                              _c("span", { staticClass: "underline-dashed" }, [
                                _vm._v(" Dimensions ")
                              ]),
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.right",
                                    value: "SKU Dimensions!",
                                    expression: "'SKU Dimensions!'",
                                    modifiers: { hover: true, right: true }
                                  }
                                ],
                                staticClass:
                                  "icon-tutorial ml-4 vam text-gray-400"
                              })
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "head(sku)",
                          fn: function() {
                            return [_vm._v(" SKU ")]
                          },
                          proxy: true
                        },
                        {
                          key: "head(quantity)",
                          fn: function() {
                            return [_vm._v(" QTY. * ")]
                          },
                          proxy: true
                        },
                        {
                          key: "head(value)",
                          fn: function() {
                            return [
                              _vm._v(" Value (₹) "),
                              _vm.form.isTaxInvoice == 1 &&
                              _vm.user.invoicing &&
                              _vm.form.orderType != "REVERSE"
                                ? _c("span", [_vm._v("*")])
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "cell(sku)",
                          fn: function(data) {
                            return [_vm._v(" " + _vm._s(data.item.sku) + " ")]
                          }
                        },
                        {
                          key: "cell(selected)",
                          fn: function(ref) {
                            var item = ref.item
                            var key = ref.field.key
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center w-sm-50 align-items-center"
                                },
                                [
                                  _c("b-form-checkbox", {
                                    on: {
                                      input: function($event) {
                                        return _vm.deSelect($event)
                                      }
                                    },
                                    model: {
                                      value: item.selected,
                                      callback: function($$v) {
                                        _vm.$set(item, "selected", $$v)
                                      },
                                      expression: "item.selected"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(name)",
                          fn: function(data) {
                            return [
                              _c("b-form-input", {
                                staticClass: "input-sm sku-name w40",
                                attrs: {
                                  placeholder: "Product Name",
                                  maxlength: "100"
                                },
                                model: {
                                  value: data.item.name,
                                  callback: function($$v) {
                                    _vm.$set(data.item, "name", $$v)
                                  },
                                  expression: "data.item.name"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "cell(dimensions)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "small w40",
                                    attrs: {
                                      placeholder: "0",
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: data.item.dimensions.length,
                                      callback: function($$v) {
                                        _vm.$set(
                                          data.item.dimensions,
                                          "length",
                                          $$v
                                        )
                                      },
                                      expression: "data.item.dimensions.length"
                                    }
                                  }),
                                  _c("span", { staticClass: "px-4" }, [
                                    _vm._v("x")
                                  ]),
                                  _c("b-form-input", {
                                    staticClass: "small w40",
                                    attrs: {
                                      placeholder: "0",
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: data.item.dimensions.width,
                                      callback: function($$v) {
                                        _vm.$set(
                                          data.item.dimensions,
                                          "width",
                                          $$v
                                        )
                                      },
                                      expression: "data.item.dimensions.width"
                                    }
                                  }),
                                  _c("span", { staticClass: "px-4" }, [
                                    _vm._v("x")
                                  ]),
                                  _c("b-form-input", {
                                    staticClass: "small w40",
                                    attrs: {
                                      placeholder: "0",
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: data.item.dimensions.height,
                                      callback: function($$v) {
                                        _vm.$set(
                                          data.item.dimensions,
                                          "height",
                                          $$v
                                        )
                                      },
                                      expression: "data.item.dimensions.height"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(weight)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "small w72",
                                    attrs: {
                                      placeholder: "0.00",
                                      step: ".01",
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: data.item.weight,
                                      callback: function($$v) {
                                        _vm.$set(data.item, "weight", $$v)
                                      },
                                      expression: "data.item.weight"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(quantity)",
                          fn: function(data) {
                            return [
                              _c("b-form-input", {
                                staticClass: "small w40",
                                attrs: {
                                  placeholder: "0",
                                  type: "number",
                                  min: "0"
                                },
                                model: {
                                  value: data.item.quantity,
                                  callback: function($$v) {
                                    _vm.$set(data.item, "quantity", $$v)
                                  },
                                  expression: "data.item.quantity"
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "cell(value)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "small w72",
                                    attrs: {
                                      step: ".01",
                                      placeholder: "0.00",
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: data.item.value,
                                      callback: function($$v) {
                                        _vm.$set(data.item, "value", $$v)
                                      },
                                      expression: "data.item.value"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(hsn)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "small w72",
                                    attrs: { placeholder: "123456" },
                                    model: {
                                      value: data.item.hsn,
                                      callback: function($$v) {
                                        _vm.$set(data.item, "hsn", $$v)
                                      },
                                      expression: "data.item.hsn"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(discount)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "small w72",
                                    attrs: {
                                      step: ".01",
                                      placeholder: "0.00",
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: data.item.discount,
                                      callback: function($$v) {
                                        _vm.$set(data.item, "discount", $$v)
                                      },
                                      expression: "data.item.discount"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "cell(taxRate)",
                          fn: function(data) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("b-form-input", {
                                    staticClass: "small w72",
                                    attrs: {
                                      step: ".01",
                                      placeholder: "0.00",
                                      type: "number",
                                      min: "0"
                                    },
                                    model: {
                                      value: data.item.taxRate,
                                      callback: function($$v) {
                                        _vm.$set(data.item, "taxRate", $$v)
                                      },
                                      expression: "data.item.taxRate"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ]),
              _vm.fields[0].key == "sku" || _vm.fields[1].key == "sku"
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "fs18 lh28 fw500 d-block pt-16 pb-38 pb-md-24"
                        },
                        [_vm._v(" Net Dimensions ")]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.fields[0].key == "sku" || _vm.fields[1].key == "sku"
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("b-table", {
                          staticClass: "dark-header new-order",
                          attrs: {
                            fields: _vm.netfields,
                            items: _vm.form.packetdiamention,
                            responsive: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "head(dimensions)",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "underline-dashed" },
                                      [_vm._v(" Dimensions ")]
                                    ),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover.right",
                                          value: "Net Dimensions LxWxH in cm",
                                          expression:
                                            "'Net Dimensions LxWxH in cm'",
                                          modifiers: {
                                            hover: true,
                                            right: true
                                          }
                                        }
                                      ],
                                      staticClass:
                                        "icon-tutorial ml-4 vam text-gray-400"
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "cell(dimensions)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-items-center"
                                      },
                                      [
                                        _c("span", { staticClass: "mr-5" }, [
                                          _vm._v("L")
                                        ]),
                                        _c("b-form-input", {
                                          staticClass: "small w50px",
                                          attrs: {
                                            required: "",
                                            placeholder: "0",
                                            type: "number",
                                            min: "1"
                                          },
                                          model: {
                                            value: data.item.itemLength,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemLength",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemLength"
                                          }
                                        }),
                                        _c("span", { staticClass: "px-4" }, [
                                          _vm._v("x")
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "mr-5 ml-5" },
                                          [_vm._v("W")]
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "small w50px",
                                          attrs: {
                                            required: "",
                                            placeholder: "0",
                                            type: "number",
                                            min: "1"
                                          },
                                          model: {
                                            value: data.item.itemWidth,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemWidth",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemWidth"
                                          }
                                        }),
                                        _c("span", { staticClass: "px-4" }, [
                                          _vm._v("x")
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "mr-5 ml-5" },
                                          [_vm._v("H")]
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "small w50px",
                                          attrs: {
                                            required: "",
                                            placeholder: "0",
                                            type: "number",
                                            min: "1"
                                          },
                                          model: {
                                            value: data.item.itemHeight,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemHeight",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemHeight"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(weight)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-items-center"
                                      },
                                      [
                                        _c("b-form-input", {
                                          staticClass: "small w72 net-weight",
                                          attrs: {
                                            required: "",
                                            step: ".01",
                                            type: "number",
                                            min: "0.01",
                                            placeholder: "0.00"
                                          },
                                          model: {
                                            value: data.item.itemWeight,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "itemWeight",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.itemWeight"
                                          }
                                        }),
                                        _c("span", { staticClass: "ml-4" }, [
                                          _vm._v(" kg ")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3088289305
                          ),
                          model: {
                            value: _vm.form.packetdiamention,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "packetdiamention", $$v)
                            },
                            expression: "form.packetdiamention"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.form.isTaxInvoice == "1" &&
              _vm.user.invoicing &&
              _vm.form.orderType != "REVERSE"
                ? _c("div", { staticClass: "row mt-20 mb-20" }, [
                    _c("div", { staticClass: "col-md-7 col-12" }, [
                      _c(
                        "span",
                        { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                        [_vm._v(" Additional Information ")]
                      ),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-3 col-6" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "wsnw",
                                attrs: {
                                  id: "invoiceNumber-field",
                                  label: "Invoice Number",
                                  "label-for": "invoiceNumber"
                                }
                              },
                              [
                                _c("b-input", {
                                  ref: "invoiceNumber",
                                  attrs: {
                                    id: "invoiceNumber",
                                    placeholder: "Invoice number",
                                    type: "text",
                                    title: "Invoice number",
                                    required: "",
                                    maxlength: "15",
                                    minlength: "3"
                                  },
                                  model: {
                                    value: _vm.form.invoiceNumber,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "invoiceNumber", $$v)
                                    },
                                    expression: "form.invoiceNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-3 col-6" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "invoiceDate-field",
                                  label: "Invoice Date",
                                  "label-for": "invoiceDate"
                                }
                              },
                              [
                                _c(
                                  "date-picker",
                                  {
                                    ref: "invoiceDate",
                                    attrs: {
                                      id: "invoiceDate",
                                      placeholder: "yyyy-mm-dd",
                                      type: "date",
                                      "prefix-class": "xmx",
                                      title: "Invoice date",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.form.invoiceDate,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "invoiceDate", $$v)
                                      },
                                      expression: "form.invoiceDate"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "icon-calendar",
                                      attrs: { slot: "icon-calendar" },
                                      slot: "icon-calendar"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-3 col-6" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "wsnw",
                                attrs: {
                                  id: "otherCharges-field",
                                  label: "Shipping / Other Charges",
                                  "label-for": "otherCharges"
                                }
                              },
                              [
                                _c(
                                  "b-input-group",
                                  { attrs: { append: "₹" } },
                                  [
                                    _c("b-input", {
                                      ref: "otherCharges",
                                      attrs: {
                                        id: "otherCharges",
                                        placeholder: "0.00",
                                        title: "Shipping / Other charges",
                                        type: "number",
                                        min: "0",
                                        step: "0.01"
                                      },
                                      model: {
                                        value: _vm.form.otherCharges,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "otherCharges",
                                            $$v
                                          )
                                        },
                                        expression: "form.otherCharges"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "m-0 mr-8 mb-20",
                  attrs: { variant: "primary", disabled: _vm.disableSave },
                  on: {
                    click: function($event) {
                      return _vm.saveButton("onlySave")
                    }
                  }
                },
                [_vm._v("SAVE")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "m-0 mb-20",
                  attrs: {
                    id: "btnHide",
                    variant: "primary",
                    type: "submit",
                    disabled: _vm.disableSave
                  }
                },
                [_vm._v("SAVE & BOOK")]
              ),
              _vm.splitOrder
                ? _c(
                    "b-button",
                    {
                      staticClass: "ml-10 mb-20",
                      attrs: {
                        id: "btnHide",
                        variant: "primary",
                        disabled: _vm.disableCreate
                      },
                      on: {
                        click: function($event) {
                          return _vm.saveButton("createNew")
                        }
                      }
                    },
                    [_vm._v("CREATE NEW")]
                  )
                : _vm._e(),
              _c("EditCalcPricing", {
                ref: "editcalcpricing",
                attrs: { formInfo: _vm.calcForm, choiceData: _vm.calcData },
                on: {
                  openModal: _vm.openPricingModal,
                  "update:formInfo": function($event) {
                    _vm.calcForm = $event
                  },
                  "update:form-info": function($event) {
                    _vm.calcForm = $event
                  },
                  "update:choiceData": function($event) {
                    _vm.calcData = $event
                  },
                  "update:choice-data": function($event) {
                    _vm.calcData = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }