var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid pt-64 pt-md-80 pb-80 pb-md-0" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-4 col-md-6 pt-16 d-flex align-items-center" },
          [
            _c("h2", { staticClass: "lh32 ml-8 " }, [
              _vm._v(" OrderID "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.order.importedID,
                      expression: "order.importedID",
                      arg: "copy"
                    },
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.top",
                      modifiers: { hover: true, top: true }
                    }
                  ],
                  staticClass: "cp underline-dashed text-decoration-none",
                  attrs: { title: "Click to copy!" },
                  on: { click: _vm.copySuccess }
                },
                [_vm._v(_vm._s(_vm.order.importedID))]
              )
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "col-4 col-md-6 pt-16 text-right pr-0" },
          [
            _vm.order.bookButton == true
              ? _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-block mr-8",
                    attrs: {
                      disabled: _vm.order.bookButtonDisabled == true,
                      variant: "primary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.singleOrderBookDetails(_vm.order.id)
                      }
                    }
                  },
                  [_vm._v(" Book ")]
                )
              : _vm._e(),
            _vm.order.editButton == true &&
            _vm.order.finalStatus != "Cancelled" &&
            !(_vm.shippingStatus == "Processing")
              ? _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-block mr-8",
                    attrs: {
                      variant: "primary",
                      disabled: _vm.order.editButtonDisabled == true
                    },
                    on: {
                      click: function($event) {
                        return _vm.fullEditOrder(_vm.order.id)
                      }
                    }
                  },
                  [_vm._v(" Edit Order ")]
                )
              : _vm._e(),
            _vm.order.cancelButton == true && _vm.order.status != 2
              ? _c(
                  "b-button",
                  {
                    staticClass: "d-none d-md-inline-block mr-8",
                    attrs: {
                      variant: "primary",
                      disabled: _vm.order.cancelButtonDisabled == true
                    },
                    on: { click: _vm.openCancelModal }
                  },
                  [_vm._v(" Cancel Order ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.clickOutsideMobileMenu,
                    expression: "clickOutsideMobileMenu"
                  }
                ],
                staticClass:
                  "order-menu order-menu__mobile d-sm-inline-block d-md-none bottom"
              },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function($event) {
                        _vm.mobileMenuToggle = !_vm.mobileMenuToggle
                      }
                    }
                  },
                  [_c("i", { staticClass: "icon-more fs16" })]
                ),
                _c(
                  "ul",
                  {
                    staticClass: "order-menu__list",
                    class: {
                      "order-menu__list--collapsed": _vm.mobileMenuToggle
                    }
                  },
                  [
                    _c(
                      "li",
                      [
                        _vm.order.bookButton == true
                          ? _c(
                              "b-link",
                              {
                                attrs: {
                                  disabled: _vm.order.bookButtonDisabled == true
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.singleOrderBookDetails(
                                      _vm.order.id
                                    )
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-cancel fs20 pr-12 text-blue"
                                }),
                                _c("span", { staticClass: "fs14 lh24" }, [
                                  _vm._v(" Book ")
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _vm.order.editButton == true &&
                        _vm.order.finalStatus != "Cancelled" &&
                        !(_vm.shippingStatus == "Processing")
                          ? _c(
                              "b-link",
                              {
                                attrs: {
                                  disabled: _vm.order.editButtonDisabled == true
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.fullEditOrder(_vm.order.id)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-edit fs20 pr-12 text-blue"
                                }),
                                _c("span", { staticClass: "fs14 lh24" }, [
                                  _vm._v(" Edit Order ")
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _vm.order.cancelButton == true
                          ? _c(
                              "b-link",
                              {
                                attrs: {
                                  disabled:
                                    _vm.order.cancelButtonDisabled == true
                                },
                                on: { click: _vm.openCancelModal }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-cancel fs20 pr-12 text-blue"
                                }),
                                _c("span", { staticClass: "fs14 lh24" }, [
                                  _vm._v(" Cancel Order ")
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row pt-16" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            {
              staticClass: "orderdetail-grid",
              staticStyle: {
                display: "inline-grid",
                "grid-auto-flow": "column",
                "grid-auto-columns": "1fr",
                "grid-gap": "0.25rem"
              }
            },
            [
              _c("div", { staticClass: "shipment" }, [
                _c("span", { staticClass: "fs16 lh24 fw500" }, [
                  _vm._v("Order Details")
                ]),
                _c("div", { staticClass: "row pt-16" }, [
                  _c("div", { staticClass: "col-6 col-md-4" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                      },
                      [_vm._v(" Order Date ")]
                    ),
                    _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                      _vm._v(" " + _vm._s(_vm._f("date")(_vm.order.date)) + " ")
                    ])
                  ]),
                  _c("div", { staticClass: "col-6 col-md-4" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                      },
                      [_vm._v(" Shipment ID ")]
                    ),
                    _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                      _vm._v(" " + _vm._s(_vm.order.shipmentID) + " ")
                    ])
                  ]),
                  _vm._m(0),
                  _vm._m(1),
                  _c("div", { staticClass: "col-6 col-md-4 pt-20" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block"
                      },
                      [_vm._v(" Channel ")]
                    ),
                    _vm.order.channel > -1
                      ? _c("span", [
                          _vm.channels[_vm.order.channel].name == "MPS" ||
                          _vm.channels[_vm.order.channel].name == "API"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("uppercase")(
                                        _vm.channels[_vm.order.channel].name
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _c(
                                "span",
                                { staticClass: "fs16 lh24 text-gray-900" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("capitalize")(
                                          _vm._f("lowercase")(
                                            _vm.channels[_vm.order.channel].name
                                          )
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                        ])
                      : _c("span", [_vm._v(" N/A ")])
                  ]),
                  _vm._m(2)
                ])
              ]),
              _c("div", { staticClass: "mode" }, [
                _c("i", {
                  staticClass:
                    "icon-surface fs16 mr-16 mb-4 mb-md-0 text-gray-600 d-block d-md-inline-flex"
                }),
                _c("div", { staticClass: "d-inline" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Mode Type ")
                  ]),
                  _c(
                    "span",
                    { staticClass: "d-block fs16 lh24 fw500 text-gray-900" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.staticName(
                              _vm.$store.getters.modes,
                              _vm.order.mode
                            ) != "N/A"
                              ? _vm.staticName(
                                  _vm.$store.getters.modes,
                                  _vm.order.mode
                                )
                              : _vm.staticName(
                                  _vm.$store.getters.modes,
                                  _vm.order.packageDetails[0].modeID
                                )
                          ) +
                          " "
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "order" }, [
                _c("i", {
                  staticClass:
                    "icon-prepaid fs16 mr-16 mb-4 mb-md-0 text-gray-600"
                }),
                _c("div", { staticClass: "d-inline" }, [
                  _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                    _vm._v(" Order Type ")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass:
                        "d-block fs16 lh24 fw500 text-gray-900 text-uppercase"
                    },
                    [_vm._v(" " + _vm._s(_vm.order.type) + " ")]
                  )
                ])
              ]),
              _vm._m(3),
              _c("div", { staticClass: "customer" }, [
                _c("span", { staticClass: "fs16 lh24 fw500" }, [
                  _vm._v("Customer Details")
                ]),
                _c("div", { staticClass: "pt-20 d-flex" }, [
                  _c("div", { staticClass: "mr-16 mb-16 map-wrapper" }),
                  _c("div", { staticClass: "d-inline-flex flex-column" }, [
                    _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                      _vm._v(" " + _vm._s(_vm.order.customer.name) + " ")
                    ]),
                    _c(
                      "span",
                      { staticClass: "fs14 lh16 pb-14 text-gray-600" },
                      [_vm._v(" " + _vm._s(_vm.order.customer.contact) + " ")]
                    ),
                    _c("span", { staticClass: "fs12 lh24 text-gray-600" }, [
                      _vm._v(" Address ")
                    ]),
                    _c("span", {
                      staticClass: "fs14 lh16 text-gray-900 mw240",
                      domProps: {
                        innerHTML: _vm._s(_vm.order.customer.address)
                      }
                    }),
                    _vm.order.customerData.email
                      ? _c(
                          "span",
                          { staticClass: "fs12 pt-10 lh24 text-gray-600" },
                          [_vm._v(" Email ")]
                        )
                      : _vm._e(),
                    _vm.order.customerData.email
                      ? _c(
                          "span",
                          { staticClass: "fs14 lh16 text-gray-900 mw240" },
                          [_vm._v(_vm._s(_vm.order.customerData.email))]
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _c("div", { staticClass: "pickup" }, [
                _c("span", { staticClass: "fs16 lh24 fw500" }, [
                  _vm._v("Pickup Details")
                ]),
                _c("div", { staticClass: "pt-20 d-flex" }, [
                  _c("div", { staticClass: "mr-16 mb-16 map-wrapper" }),
                  _c("div", { staticClass: "d-inline-flex flex-column" }, [
                    _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                      _vm._v(" " + _vm._s(_vm.order.pickupDetails.name) + " ")
                    ]),
                    _c(
                      "span",
                      { staticClass: "fs14 lh16 pb-14 text-gray-600" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.order.pickupDetails.contact) + " "
                        )
                      ]
                    ),
                    _c("span", { staticClass: "fs12 lh24 text-gray-600" }, [
                      _vm._v(" Address ")
                    ]),
                    _c(
                      "span",
                      { staticClass: "fs14 lh16 text-gray-900 mw240" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.order.pickupDetails.address) + " "
                        )
                      ]
                    )
                  ])
                ])
              ]),
              _vm.shippingStatus
                ? _c(
                    "div",
                    {
                      staticClass: "shipping-status",
                      class: _vm.shippingStatus.toLowerCase()
                    },
                    [
                      _c("span", { staticClass: "fs16 lh24 fw500" }, [
                        _vm._v("Order Status")
                      ]),
                      _c("div", { staticClass: "status-dot" }, [
                        _c("div", { staticClass: "dot" }),
                        _c("span", { staticClass: "fs16 lh24 fw500 ml-30" }, [
                          _vm._v(" " + _vm._s(_vm.shippingStatus))
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "package" },
                [
                  _c("span", { staticClass: "fs16 lh24 d-block mb-12 fw500" }, [
                    _vm._v("Package Details")
                  ]),
                  _c("b-table", {
                    staticClass: "dark-header w-footer",
                    attrs: {
                      fields: _vm.computedFileds,
                      items: _vm.order.packageDetails,
                      "foot-clone": "",
                      responsive: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "head(quantity)",
                        fn: function() {
                          return [_vm._v(" Qty. ")]
                        },
                        proxy: true
                      },
                      {
                        key: "head(value)",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "d-block text-right" }, [
                              _vm._v("Value")
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "cell(dimensions(CM))",
                        fn: function(data) {
                          return [
                            _c("div", { staticClass: "w120px" }, [
                              _vm._v(
                                " " +
                                  _vm._s(data.item.length) +
                                  " x " +
                                  _vm._s(data.item.width) +
                                  " x " +
                                  _vm._s(data.item.height) +
                                  " "
                              )
                            ])
                          ]
                        }
                      },
                      {
                        key: "cell(weight(Kg))",
                        fn: function(data) {
                          return [_vm._v(" " + _vm._s(data.item.weight) + " ")]
                        }
                      },
                      {
                        key: "cell(SKU)",
                        fn: function(data) {
                          return [
                            _c("span", { staticClass: "d-block" }, [
                              _vm._v(_vm._s(data.item.sku))
                            ])
                          ]
                        }
                      },
                      {
                        key: "cell(value)",
                        fn: function(data) {
                          return [
                            _c("span", { staticClass: "d-block text-right" }, [
                              _vm._v("₹" + _vm._s(data.item.value))
                            ])
                          ]
                        }
                      },
                      {
                        key: "cell(price)",
                        fn: function(data) {
                          return [
                            _c("span", { staticClass: "d-block" }, [
                              _vm._v(
                                _vm._s(
                                  isNaN(data.item.price)
                                    ? ""
                                    : data.item && data.item.price == null
                                    ? "0"
                                    : parseFloat(data.item.price).toFixed(2)
                                )
                              )
                            ])
                          ]
                        }
                      },
                      {
                        key: "foot(sku)",
                        fn: function() {
                          return [_c("span")]
                        },
                        proxy: true
                      },
                      {
                        key: "foot(category)",
                        fn: function() {
                          return [_c("span")]
                        },
                        proxy: true
                      },
                      {
                        key: "foot(name)",
                        fn: function() {
                          return [_c("span", [_vm._v("Total Value")])]
                        },
                        proxy: true
                      },
                      {
                        key: "foot(dimensions(CM))",
                        fn: function() {
                          return [_c("span")]
                        },
                        proxy: true
                      },
                      {
                        key: "foot(weight(Kg))",
                        fn: function() {
                          return [_c("span")]
                        },
                        proxy: true
                      },
                      {
                        key: "foot(quantity)",
                        fn: function() {
                          return [_c("span")]
                        },
                        proxy: true
                      },
                      {
                        key: "foot(price)",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "d-block fw700" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.channels[_vm.order.channel] &&
                                      _vm.channels[_vm.order.channel].name ==
                                        "international"
                                      ? "$"
                                      : "₹"
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.order.totalValue
                                      ? _vm.order.totalValue
                                      : 0
                                  )
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ]
          )
        ])
      ]),
      _c("EditOrderModal", {
        ref: "editOrderModal",
        attrs: {
          selectedEditOrder: _vm.selectedEditOrder,
          editOrderID: _vm.orderIDs,
          orderIDs: _vm.orderIDs
        }
      }),
      _c("orderSkuEdit", {
        ref: "editOrderSku",
        attrs: {
          from: _vm.orderDetails,
          orderIDs: _vm.orderIDs,
          saveasSKUFlag: _vm.saveasSKUFlag
        },
        on: { updatePackages: _vm.updatePackageDim, reFresh: _vm.reFresh }
      }),
      _c("ShipmentsCancelModal", {
        ref: "cancelOrderModal",
        attrs: { orderID: _vm.order.id }
      }),
      _vm.editFormData
        ? _c("fullOrderEdit", {
            ref: "fullOrderEditModal",
            attrs: { editFormData: _vm.editFormData },
            on: { reFresh: _vm.reFresh }
          })
        : _vm._e(),
      _c("ShipmentBookReverse", { ref: "bookReverseModal" }),
      _c("choiceDialog", {
        ref: "choiceDialog",
        attrs: {
          bookOrderFlag: _vm.bookOrderFlag,
          formInfo: _vm.formInfo,
          choiceData: _vm.choiceData
        },
        on: { openModal: _vm.openPricingModal }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 col-md-4 pt-20 pt-md-0" }, [
      _c(
        "span",
        { staticClass: "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block" },
        [_vm._v(" Manifest ID ")]
      ),
      _c("span", { staticClass: "fs16 lh24" }, [_vm._v(" N/A ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 col-md-4 pt-20" }, [
      _c(
        "span",
        { staticClass: "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block" },
        [_vm._v(" Remittance Date ")]
      ),
      _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [_vm._v(" N/A ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6 col-md-4 pt-20" }, [
      _c(
        "span",
        { staticClass: "fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block" },
        [_vm._v(" Estimated Amount ")]
      ),
      _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [_vm._v(" N/A ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "carrier d-flex flex-column justify-content-around" },
      [
        _c("div", { staticClass: "align-items-center" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6 col-md" }, [
              _c("img", {
                staticClass: "w40",
                attrs: {
                  src: require("@/assets/svg/shyplite-icon-blue.svg"),
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "col-6 col-md" }, [
              _c("span", { staticClass: "d-block fs20 lh24 text-gray-900" }, [
                _vm._v(" N/A ")
              ]),
              _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                _vm._v(" Carrier ")
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row pt-24 pt-md-0" }, [
          _c("div", { staticClass: "col-6 col-md" }, [
            _c("div", [
              _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                _vm._v(" N/A ")
              ]),
              _c("span", { staticClass: "fs12 lh16 text-gray-600 d-block" }, [
                _vm._v(" Estimated Delivery ")
              ])
            ])
          ]),
          _c("div", { staticClass: "col-6 col-md" }, [
            _c("div", [
              _c("span", { staticClass: "fs16 lh24 text-gray-900" }, [
                _vm._v(" N/A ")
              ]),
              _c("span", { staticClass: "fs12 lh16 text-gray-600 d-block" }, [
                _vm._v(" Tracking No.(AWB) ")
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }