<template>
	<div class="container-fluid pt-64 pt-md-80 pb-80 pb-md-0">
		<div class="row">
			<div class="col-4 col-md-6 pt-16 d-flex align-items-center">
				<!-- <b-button v-b-tooltip.hover.top title="Previous Order" variant="icon-sm" @click="goPrev" v-if="order.prev != null">
					<i class="icon-previous fs9"></i>
				</b-button>
 -->				<h2 class="lh32 ml-8 " >
					OrderID <span @click="copySuccess" v-clipboard:copy="order.importedID" v-b-tooltip.hover.top title="Click to copy!" class="cp underline-dashed text-decoration-none">{{ order.importedID }}</span> 
				</h2>
				<!-- <b-button v-b-tooltip.hover.top title="Next Order" class="ml-8" variant="icon-sm" @click="goNext" v-if="order.next != null">
					<i style="transform: scale(-1,1);" class="icon-previous fs9"></i>
				</b-button> -->
			</div>

			<div class="col-4 col-md-6 pt-16 text-right pr-0">
				<b-button v-if="order.bookButton == true" :disabled="order.bookButtonDisabled == true" @click="singleOrderBookDetails(order.id)" variant="primary" class="d-none d-md-inline-block mr-8">
					Book
				</b-button>
				<b-button variant="primary" class="d-none d-md-inline-block mr-8" v-if="order.editButton == true && order.finalStatus != 'Cancelled' && !(shippingStatus == 'Processing')" :disabled="order.editButtonDisabled == true" @click="fullEditOrder(order.id)" >
					Edit Order
				</b-button>
				<b-button variant="primary" class="d-none d-md-inline-block mr-8" v-if="order.cancelButton == true && order.status != 2" :disabled="order.cancelButtonDisabled == true" @click="openCancelModal" >
					Cancel Order
				</b-button>	

				<!-- Book, Edit order, Cancel Order  button for mobile view  -->

				<div class="order-menu order-menu__mobile d-sm-inline-block d-md-none bottom" v-click-outside="clickOutsideMobileMenu">
					<b-button @click="mobileMenuToggle = !mobileMenuToggle" variant="primary">
						<i class="icon-more fs16"></i>
					</b-button>
					<ul class="order-menu__list" :class="{ 'order-menu__list--collapsed': mobileMenuToggle }">
						<li>
							<b-link v-if="order.bookButton == true" :disabled="order.bookButtonDisabled == true" @click="singleOrderBookDetails(order.id)">
								<i class="icon-cancel fs20 pr-12 text-blue"></i>
								<span class="fs14 lh24">
									Book
								</span>
							</b-link>
						</li>
						<li>
							<b-link v-if="order.editButton == true && order.finalStatus != 'Cancelled' && !(shippingStatus == 'Processing')" :disabled="order.editButtonDisabled == true" @click="fullEditOrder(order.id)">
								<i class="icon-edit fs20 pr-12 text-blue"></i>
								<span class="fs14 lh24">
									Edit Order
								</span>
							</b-link>
						</li>
						<li>
							<b-link v-if="order.cancelButton == true" :disabled="order.cancelButtonDisabled == true" @click="openCancelModal">
								<i class="icon-cancel fs20 pr-12 text-blue"></i>
								<span class="fs14 lh24">
									Cancel Order
								</span>
							</b-link>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="row pt-16">
			<div class="col">
				<div class="orderdetail-grid" style="display: inline-grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 0.25rem;">
					<div class="shipment">
						<span class="fs16 lh24 fw500">Order Details</span>
						<div class="row pt-16">
							<div class="col-6 col-md-4">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Order Date
								</span>
								<span class="fs16 lh24 text-gray-900">
									{{ order.date | date }}
								</span>
							</div>
							<div class="col-6 col-md-4">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Shipment ID
								</span>
								<span class="fs16 lh24 text-gray-900">
									{{ order.shipmentID }}
								</span>
							</div>
							<div class="col-6 col-md-4 pt-20 pt-md-0">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Manifest ID
								</span>
								<span class="fs16 lh24">
									N/A
								</span>
							</div>

							<div class="col-6 col-md-4 pt-20">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Remittance Date
								</span>
								<span class="fs16 lh24 text-gray-900">
									N/A
								</span>
							</div>
							<div class="col-6 col-md-4 pt-20">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Channel
								</span>
								<span v-if="order.channel > -1">
									<span v-if="channels[order.channel].name == 'MPS' || channels[order.channel].name == 'API'">
										{{ channels[order.channel].name | uppercase }}
									</span>
									<span v-else class="fs16 lh24 text-gray-900">
										{{ channels[order.channel].name | lowercase | capitalize }}
									</span>
								</span>
								<span v-else>
									N/A
								</span>
							</div>
							<div class="col-6 col-md-4 pt-20">
								<span class="fs12 lh16 text-gray-600 mb-8 mb-md-4 d-block">
									Estimated Amount
								</span>
								<span class="fs16 lh24 text-gray-900">
									N/A
								</span>
							</div>
						</div>
					</div>

					<div class="mode">
						<i class="icon-surface fs16 mr-16 mb-4 mb-md-0 text-gray-600 d-block d-md-inline-flex"></i>
						<div class="d-inline">
							<span class="fs12 lh16 text-gray-600">
								Mode Type
							</span>
							<span class="d-block fs16 lh24 fw500 text-gray-900">
								{{ staticName($store.getters.modes, order.mode) != 'N/A' ? staticName($store.getters.modes, order.mode) : staticName($store.getters.modes, order.packageDetails[0].modeID)  }}
							</span>
						</div>
					</div>
					<div class="order">
						<i class="icon-prepaid fs16 mr-16 mb-4 mb-md-0 text-gray-600"></i>
						<div class="d-inline">
							<span class="fs12 lh16 text-gray-600">
								Order Type
							</span>
							<span class="d-block fs16 lh24 fw500 text-gray-900 text-uppercase">
								{{ order.type }}
							</span>
						</div>
					</div>
					<div class="carrier d-flex flex-column justify-content-around">
						<div class="align-items-center">
							<div class="row">
								<div class="col-6 col-md">
									<!-- <img :src="require(`@/assets/images/${carrier.code}.png`)" class="pr-12 w120px" alt="" /> -->
									<img src="@/assets/svg/shyplite-icon-blue.svg" class="w40" alt="">
								</div>
								<div class="col-6 col-md">
									<span class="d-block fs20 lh24 text-gray-900">
										N/A
									</span>
									<span class="fs12 lh16 text-gray-600">
										Carrier
									</span>
								</div>
							</div>
						</div>
						<div class="row pt-24 pt-md-0">
							<div class="col-6 col-md">
								<div>
									<span class="fs16 lh24 text-gray-900">
										N/A
									</span>
									<span class="fs12 lh16 text-gray-600 d-block">
										Estimated Delivery
									</span>
								</div>
							</div>
							<div class="col-6 col-md">
								<div>
									<span class="fs16 lh24 text-gray-900">
										N/A
									</span>
									<span class="fs12 lh16 text-gray-600 d-block">
										Tracking No.(AWB)
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="customer">
						<span class="fs16 lh24 fw500">Customer Details</span>
						<div class="pt-20 d-flex">
							<div class="mr-16 mb-16 map-wrapper">
								<!-- <img src="@/assets/img/map.png" alt="" /> -->
							</div>
							<div class="d-inline-flex flex-column">
								<span class="fs16 lh24 text-gray-900">
									{{ order.customer.name }}
								</span>
								<span class="fs14 lh16 pb-14 text-gray-600">
									{{ order.customer.contact }}
								</span>
								<span class="fs12 lh24 text-gray-600">
									Address
								</span>
								<span v-html="order.customer.address" class="fs14 lh16 text-gray-900 mw240"></span>
								<span v-if="order.customerData.email" class="fs12 pt-10 lh24 text-gray-600">
									Email
								</span>
								<span v-if="order.customerData.email" class="fs14 lh16 text-gray-900 mw240">{{order.customerData.email}}</span>
							</div>
						</div>
					</div>
					<div class="pickup">
						<span class="fs16 lh24 fw500">Pickup Details</span>
						<div class="pt-20 d-flex">
							<div class="mr-16 mb-16 map-wrapper">
								<!-- <img src="@/assets/img/map.png" alt="" /> -->
							</div>
							<div class="d-inline-flex flex-column">
								<span class="fs16 lh24 text-gray-900">
									{{ order.pickupDetails.name }}
								</span>
								<span class="fs14 lh16 pb-14 text-gray-600">
									{{ order.pickupDetails.contact }}
								</span>
								<span class="fs12 lh24 text-gray-600">
									Address
								</span>
								<span class="fs14 lh16 text-gray-900 mw240">
									{{ order.pickupDetails.address }}
								</span>
							</div>
						</div>
					</div>
					<div class="shipping-status" v-if="shippingStatus" :class="shippingStatus.toLowerCase()">
						<span class="fs16 lh24 fw500">Order Status</span>
						<div class="status-dot">
							<div class="dot"></div>
							<span class="fs16 lh24 fw500 ml-30"> {{ shippingStatus }}</span>
						</div>
					</div>
					<div class="package">
						<span class="fs16 lh24 d-block mb-12 fw500">Package Details</span>
						<b-table class="dark-header w-footer" :fields="computedFileds" :items="order.packageDetails" foot-clone responsive>
							<!-- A custom formatted header cell for field 'name' -->
							<template v-slot:head(quantity)="">
								Qty.
							</template>
							<template v-slot:head(value)="">
								<span class="d-block text-right">Value</span>
							</template>
							<!-- A custom formatted cell for field 'dimensions' -->
							<template v-slot:cell(dimensions(CM))="data">
								<div class="w120px">
									{{ data.item.length }} x
									{{ data.item.width }} x
									{{ data.item.height }}
								</div>
							</template>
							<template v-slot:cell(weight(Kg))="data">
								{{ data.item.weight }}
							</template>

							<template v-slot:cell(SKU)="data">
								<span class="d-block">{{ data.item.sku }}</span>
							</template>
							<!-- A custom formatted cell for field 'value' -->
							<template v-slot:cell(value)="data">
								<span class="d-block text-right">₹{{ data.item.value }}</span>
							</template>
							<template v-slot:cell(price)="data">
								<span class="d-block">{{ isNaN(data.item.price) ? '' : (data.item && data.item.price == null) ? '0' : parseFloat(data.item.price).toFixed(2) }}</span>
							</template>
							<!-- Footer cells render empty -->
							<template v-slot:foot(sku)="">
								<span></span>
							</template>
							<template v-slot:foot(category)="">
								<span></span>
							</template>
							<template v-slot:foot(name)="">
								<span>Total Value</span>
							</template>
							<template v-slot:foot(dimensions(CM))="">
								<span></span>
							</template>
							<template v-slot:foot(weight(Kg))="">
								<span></span>
							</template>
							<template v-slot:foot(quantity)="">
								<span></span>
							</template>
							<template v-slot:foot(price)="">
								<span class="d-block fw700"> {{ channels[order.channel] && channels[order.channel].name == 'international' ? '$' : '₹' }} {{ order.totalValue ? order.totalValue : 0 }}</span>
							</template>
						</b-table>
					</div>
				</div>
			</div>
		</div>

		<EditOrderModal :selectedEditOrder="selectedEditOrder" :editOrderID="orderIDs" :orderIDs="orderIDs" ref="editOrderModal" />
		<orderSkuEdit @updatePackages="updatePackageDim" @reFresh="reFresh" :from="orderDetails" :orderIDs="orderIDs" :saveasSKUFlag="saveasSKUFlag" ref="editOrderSku" />
		<ShipmentsCancelModal :orderID="order.id" ref="cancelOrderModal" />
		<fullOrderEdit :editFormData="editFormData" @reFresh="reFresh" ref="fullOrderEditModal" v-if="editFormData" />
		<ShipmentBookReverse ref="bookReverseModal" />
		<choiceDialog @openModal="openPricingModal" :bookOrderFlag="bookOrderFlag" :formInfo="formInfo" :choiceData="choiceData" ref="choiceDialog" />
	</div>
</template>

<script>
import vClickOutside from "v-click-outside";
import fullOrderEdit from "@/components/editOrder.vue";
import EditOrderModal from "@/components/EditOrderModal.vue";
import ShipmentBookReverse from "@/components/ShipmentBookReverse.vue";
import ShipmentsCancelModal from "@/components/ShipmentsCancelModal.vue";
import orderSkuEdit from "@/components/orderSkuEdit.vue";
import choiceDialog from "@/components/choiceDialog.vue";

export default {
	name: "ShipmentsSingleItem",
	props: ['order', 'shipment', 'tracking', 'channels', 'items', 'shipEvents', 'events', 'statuses'],
	data() {
		return {
			mobileMenuToggle: true,
			orderMenuToggle: true,
			orderDetails : false,
			acceptedChannels : [3,4,5,6,7,10],
			fields: [
			{
				key: "name",
				sortable: true,
			},
			{
				key: "SKU",
				sortable: true,
			},
			{ key: "dimensions(CM)", label: "L x W x H (CM)" },
			{
				key: "weight(Kg)",
				label: "Weight (Kg)",
				sortable: true,
			},
			{
				key: "quantity",
				sortable: true,
			},
			{
				key: "price",
				sortable: true,
			},
			],
			bookOrderFlag: "yes",
			formInfo: [],
			orderIDs: [],
			choiceData: [],
			saveasSKUFlag: '',
			editFormData: {},
			selectedEditOrder: [],

		};
	},
	components: {
		orderSkuEdit,
		fullOrderEdit,
		ShipmentBookReverse,
		ShipmentsCancelModal,
		EditOrderModal,
		choiceDialog,
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	created() {
		if(this.$route.query.fullEdit) {
			this.fullEditOrder(this.order.id)
		}
	},
	methods: {
		copySuccess() {
          this.popToast("booked", "Success", "Successfully copied to clipboard!");
        },
		goPrev() {
			this.$router.push(`/orders/${this.order.prev}`)
		},
		goNext() {
			this.$router.push(`/orders/${this.order.next}`)
		},
		reFresh({refresh}) {
			this.$emit('refreshOrder',refresh)
		},
		openEditModal() {
          this.$refs.editOrderModal.isOpen = !this.$refs.editOrderModal.isOpen;
        },
		goBack() {
			this.$router.push('/orders');
		},
		async clearSelected() {
			try {
				const res = await this.axios.post(`/shipment/${this.shipment.id}/cancel`)
				if(res.data.success == true) {
					this.tracking.isCancelled = 1
					this.popToast("booked", "Success!", 'Order has been canceled.');
				}else{
					this.popToast("failed", "Cancellation Failed!", res.data.message);
				}
			} catch(e) {
				console.log(e);
			}
		},
		async fullEditOrder(value) {

			let result = await this.axios.get(`/order/edit/${value}`)

			this.editFormData = result.data;

			this.openRebookModal();

		},
		staticName(statics, index) {
			return statics.find(i => i.value == index) ? statics.find(i => i.value == index).name: 'N/A';
		},
		openCancelModal() {
			this.$refs.cancelOrderModal.isOpen = !this.$refs.cancelOrderModal.isOpen;
			this.bottomOrderMenuToggle = true;
		},
		canceled() {
			this.order.finalStatus = 'Cancelled'
			this.order.status = 2
			this.order.cancelButton = false
			this.order.bookButton = false
		},
		openRebookModal: function() {
			this.$refs.fullOrderEditModal.isOpen = !this.$refs.fullOrderEditModal.isOpen;
			this.bottomOrderMenuToggle = true;
		},
		openBookReverseModal: function() {
			this.$refs.bookReverseModal.isOpen = !this.$refs.bookReverseModal.isOpen;
			this.bottomOrderMenuToggle = true;
		},
		clickOutsideMenu() {
			this.orderMenuToggle = true;
		},
		clickOutsideMobileMenu() {
			this.mobileMenuToggle = true;
		},
		openEditSkuModal() {
          this.$refs.editOrderSku.isOpen = !this.$refs.editOrderSku.isOpen;
        },
		openPricingModal: function() {
			this.$refs.choiceDialog.isOpen = !this.$refs
			.choiceDialog.isOpen;
		},
		async downloadToast() {
			try {
				this.popToast("requested", "Download requested", "Your download should start now.");
				const res = await this.axios.get(`/slip/${this.shipment.id}`)
				this.download(res.data.path, res.data.name)
				this.orderMenuToggle = true;
				this.bottomOrderMenuToggle = true;
			} catch(e) {
				console.log(e);
			}
		},
		
		async singleOrderBookDetails(orderID) {

			if (this.$store.getters.user.choice == 0) {

				let shortKey = this.order.packageDetails[0];

				if(!(this.order.mode || this.order.packageDetails[0].modeID) || (Object.keys(this.order.packetdiamention).length == 0 && shortKey.quantity > 1) || (Object.keys(this.order.packetdiamention).length == 0 && this.order.packageDetails.length > 1) || (Object.keys(this.order.packetdiamention).length == 0 && (shortKey.length == 0 || shortKey.width == 0 || shortKey.height == 0 || shortKey.weight == 0 || shortKey.mode == null))) {
					this.orderIDs = orderID;
					this.orderDetails = true

					let packageArray = this.order.packageDetails.filter(i => i.name != 'Net Dimension')

					if(packageArray.length == 1 && this.acceptedChannel && shortKey.hasOwnProperty('sku') && shortKey.quantity == 1 && (shortKey.length == 0 || shortKey.width == 0 || shortKey.height == 0 || shortKey.weight == 0 || shortKey.mode == null)) {

						this.saveasSKUFlag = true

					} else {
						this.saveasSKUFlag = false
					}

					this.openEditSkuModal();

					return false
				}

				

				let result = await this.axios.post('/bulkorder', {
					orders: [orderID]
				})

				if (result.data.success == true) {
					
					this.popToast( "booked", "Order booked", "Order Added Successfully");

					setTimeout(() => { 
	                	this.$router.push("/shipments?page=1&offset=25")
	                }, 1000)

				}
				else {
					
					this.popToast( "failed", "Booking error!", result.data.message);

					if(result.data && result.data.hasOwnProperty("skuIDs") ) {
						let orderID = result.data.orderID
						this.fullEditOrder(orderID)
					}
				}            

			}
			else {

				let shortKey = this.order.packageDetails[0];

				if ((this.$store.getters.user.choice == 1 && Object.entries(this.order.packetdiamention).length == 4) || (shortKey.hasOwnProperty('sku') && Object.entries(this.order.packageDetails).length == 1 && shortKey.quantity == 1 && shortKey.length > 0 && shortKey.width > 0 && shortKey.height > 0 && shortKey.weight > 0) ) {

					// package dimention update function
					this.updatePackageDim(orderID);
				}

				else if (this.$store.getters.user.choice == 1 && shortKey.hasOwnProperty('sku') && Object.entries(this.order.packageDetails).length > 1) { 
					this.orderIDs = orderID;
					this.saveasSKUFlag = false
					this.openEditSkuModal();
				}

				else if (this.$store.getters.user.choice == 1 && this.acceptedChannel && Object.entries(this.order.packageDetails).length == 1 && shortKey.hasOwnProperty('sku') && shortKey.quantity == 1 && (shortKey.length == 0 || shortKey.width == 0 || shortKey.height == 0 || shortKey.weight == 0)) { 
					this.orderIDs = orderID;
					this.saveasSKUFlag = true
					this.openEditSkuModal();
				}

				else if (this.$store.getters.user.choice == 1 && Object.entries(this.order.packageDetails).length == 1 && shortKey.hasOwnProperty('sku') && (Object.keys(this.order.packetdiamention).length == 0 && (shortKey.length == 0 || shortKey.width == 0 || shortKey.height == 0 || shortKey.weight == 0 || shortKey.mode == null || shortKey.quantity > 1))) {
					this.orderIDs = orderID;
					this.saveasSKUFlag = false
					this.openEditSkuModal();
				}

				else {
					this.updatePackageDim(orderID);
				}
			}
		},

		async updatePackageDim(orderID) {

			let diamentionsResult = await this.axios.post('/choice/getdiamentions', { orders: [orderID]});

			if (diamentionsResult.data.response.choice) {

          if(diamentionsResult.data.response.success && diamentionsResult.data.response.response.dimensionError) { 

                	try {

						          let response = await this.axios.post('/choice/choiceordersingle', { orders: [orderID] })

						          this.formInfo = response.data.result.orderDetail[0];
						          this.choiceData = response.data.result.response[0];
                      this.$store.commit('setGlobal',{bookfromorderdetails : true})
						          this.openPricingModal();
                	}
                	catch (excetion) {
                		
                		this.popToast('failed','Failed',excetion.response.data)
                	}
				}
				else {
					this.openEditSkuModal();
					this.orderIDs = orderID;
					this.saveasSKUFlag = false
                }

        	}
		}
	},
	computed: {
		shippingStatus() {
			return this.order.finalStatus;
		},
		noNetDimention() {
			return Object.keys(this.order.packetdiamention).length == 0;
		},
		total() {
			var total = 0;
			return this.order.packageDetails.reduce((total, item) => {
				let price = isNaN(item.price) ? 0 : item.price;
				return total + parseFloat(price);
			}, 0);
		},
		shippingStages() {
			return this.$store.getters.orderShippingStages;
		},
		carrier() {
			return this.$store.getters.carriers.filter(i => i.value == this.tracking.carrierID)[0]
		},
		acceptedChannel() {
			return this.acceptedChannels.indexOf(this.order.channel) == -1 ? false : true;
		},
		computedFileds() {
			if(this.order.packageDetails[0] && this.order.packageDetails[0].hasOwnProperty('sku')){
				return this.fields
			}
			else{
				this.fields[1].key = 'category'
				return this.fields
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.orderdetail-grid {
	display: inline-grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	grid-gap: rem(4px);
	@include media-breakpoint-up(md) {
		width: 100%;
	}
	.shipment {
		grid-column: 1 / 4;
		grid-row: 1 / 5;

		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 1 / 3;
		}
	}
	.mode {
		display: flex;
		align-items: center;
		grid-column: 4 / 5;
		grid-row: 1 / 3;
		@include media-breakpoint-down(md) {
			grid-column: 1 / 4;
			grid-row: 3 / 5;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: flex-start;
			margin-top: 0;
			grid-row: 3 / 6;
		}
	}
	.order {
		display: flex;
		align-items: center;
		grid-column: 4 / 5;
		grid-row: 3 / 5;
		@include media-breakpoint-down(md) {
			grid-column: 4 / 7;
			grid-row: 3 / 5;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: flex-start;
			margin-top: 0;
			grid-row: 3 / 6;
		}
	}
	.carrier {
		grid-column: 5 / 7;
		grid-row: 1 / 5;
		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 6 / 10;
		}
		@include media-breakpoint-down(sm) {
			grid-column: 1 / 7;
			grid-row: 10 / 12;
		}
	}
	.customer {
		grid-column: 1 / 3;
		grid-row: 5 / 9;
		@include media-breakpoint-down(md) {
			grid-column: 4 / 7;
			grid-row: 10 / 12;
		}
		@include media-breakpoint-down(sm) {
			grid-column: 1 / 7;
			grid-row: 6 / 8;
		}
	}
	.pickup {
		grid-column: 3 / 5;
		grid-row: 5 / 9;
		@include media-breakpoint-down(md) {
			grid-column: 1 / 4;
			grid-row: 10 / 12;
		}
		@include media-breakpoint-down(sm) {
			grid-column: 1 / 7;
			grid-row: 8 / 10;
		}
	}
	.timeline {
		grid-column: 5 / 7;
		grid-row: 6 / 13;
		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 12 / 14;
		}
		@include media-breakpoint-down(sm) {
			margin-top: rem(44px);
		}
	}
	.package {
		grid-column: 1 / 5;
		grid-row: 9 / 14;
		margin-bottom: rem(32px);
		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 14 / 18;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: rem(16px);
		}
	}
	.shipping-status {
		grid-column: 5 / 7;
		grid-row: 5 / 6;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: rem(16px) rem(24px) !important;
		background-color: $light-blue;
		&.booked, &.in-transit {
			background-color: rgba($light-blue, 0.6) !important;
			.status-dot {
				span {
					color: $blue;
				}
				.dot {
					background-color: $blue;
				}
			}
		}
		&.picked-up {
			background-color: rgba($light-green, 0.2) !important;
			.status-dot {
				span {
					color: $green;
				}
				.dot {
					background-color: $green;
				}
			}
		}
		&.exception, &.delayed {
			background-color: rgba($orange, 0.2) !important;
			.status-dot {
				span {
					color: $orange;
				}
				.dot {
					background-color: $orange;
				}
			}
		}
		&.cancelled, &.lost {
			background-color: rgba($red, 0.2) !important;
			.status-dot {
				span {
					color: $red;
				}
				.dot {
					background-color: $red;
				}
			}
		}
		&.out-for-delivery {
			background-color: rgba($lilac, 0.2) !important;
			.status-dot {
				span {
					color: $lilac;
				}
				.dot {
					background-color: $lilac;
				}
			}
		}
		&.delivered {
			background-color: rgba($green, 0.2) !important;
			.status-dot {
				span {
					color: $green;
				}
				.dot {
					background-color: $green;
				}
			}
		}
		&.returned, &.pending {
			background-color: rgba($gray-900, 0.2) !important;
			.status-dot {
				span {
					color: $gray-900;
				}
				.dot {
					background-color: $gray-900;
				}
			}
		}
		@include media-breakpoint-down(md) {
			grid-column: 1 / 7;
			grid-row: 5 / 6;
			max-height: rem(40px);
		}
		@include media-breakpoint-down(sm) {
			grid-column: 1 / 7;
			grid-row: 12 / 13;
		}
	}
}

.map-wrapper {
	position: relative;
	height: rem(64px);
	width: rem(64px);
	background-color: $gray-200;
	border-radius: $border-radius;
	&:after {
		display: block;
		position: absolute;
		top: calc(50% - 10px);
		left: calc(50% - 8px);
		content: "";
		background-image: url("../assets/svg/marker-gray.svg");
		//background-image: url("../assets/svg/map-marker.svg");
		background-repeat: no-repeat;
		height: rem(32px);
		width: rem(26px);
	}
}

.orderdetail-grid > div {
	background: $white;
	padding: rem(24px);
	border-radius: rem(8px);
	min-width: rem(204px);
	@include media-breakpoint-down(md) {
		min-width: auto;
	}
}

.status-dot {
	position: relative;
	.dot {
		width: 15px;
		height: 15px;
		background-color: $blue;
		border-radius: 50%;
		position: absolute;
		top: 5px;
		left: 5px;
	}
}
a.disabled {
	cursor: default;
	background: #f5f7fa!important;
}

</style>